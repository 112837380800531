import React, { Component } from 'react';
import ListPressNotes from './ListPressNotes';
import ListPressNotesHeader from './ListPressNotesHeader';
import AddPressNote from './AddPressNote';
import PreviewPressNote from './PreviewPressNote';
import EditPressNote from './EditPressNote';
import ClonePressNote from './ClonePressNote';
import DeletePressNote from './DeletePressNote';
import Loading from '../Loading';
import Login from '../Login';
import Alerts from '../Alerts';
import {authParams} from '../Lib/functions';

//import Login from './Login';

//import customData from './backPressNotes.json';
//import customDataPressNote from './backPressNote.json'; 

//const serverRoute = 'http://localhost:3002';

class App extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.username);
    console.log(this.props.serverRoute);
    let username = this.props.username;
    if (sessionStorage.getItem('username')) {
      username = sessionStorage.getItem('username');
    }
    this.state = {
      pressnotes:[],
      loading: false,
      orderField: 'lastUpdate',
      orderDir: 'desc',
      search: '',
      username:username,
      showAddPressNote: false,
      showEditPressNote: false,
      showDeletePressNote: false,
      showClonePressNote: false,
      pressnoteSelected: null,
      pressnoteToDeleted: null,
      showAlerts: false,
      preview: null,
    }
  }

  componentDidMount = () => {
    if (this.props.username || sessionStorage.getItem('username')) {
      this.setState({loading:true});
      this.getPressNotes();
    }
  }

  getPressNotes = () => {
    this.setState({loading : true});
    this.getPressNotesCall()
      .then(res => {
        const pressnotes = [];
        console.log(res);
        res.data.forEach(item => {
          pressnotes.push(item);
        })
        this.setState({pressnotes:pressnotes,loading : false});
      })
      .catch(error => {
        alert(error);
        this.setState({pressnotes:[],loading : false});
      });
  }

  getPressNotesCall = (obj = {}) => {
    return new Promise((resolve, reject) => {
      
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/pressnotes?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/pressnotes';
      console.log(urlServer);
      fetch(urlServer)
        .then(res => res.json())
        .then(json => {
          if (json.Response === 'True') {
            resolve(json);
          } else {
            reject(json.Error);
          }
        })
        .catch(err => {
          console.log(err);
          reject('api error');
        });
        
        //resolve(customData);
    });
  }

  handleSearch = (event) => {
    this.setState({search:event.target.value});
  }

  showAddPressNote = () => {
    this.setState({showAddPressNote:true});
  }
  
  changeOrder = (order, previousOrder) => {
    console.log(order+' - '+previousOrder);
    let orderDir = this.state.orderDir;
    if (order === previousOrder) {
      if (orderDir === "asc") {
        orderDir = "desc";
      } else {
        orderDir = "asc";
      }
    } else {
        orderDir = "asc";
    }
    this.setState({orderDir:orderDir,orderField:order});
  }

  savePressNote = (pressnote, pdf=null) => {
    console.log(pdf);
    if (pressnote.url.trim() === '') {
      this.setState({showAlerts:true,alertMessage:'Añade la url donde se colocará la Nota de Prensa.'});
    } else if (pressnote.url.indexOf(' ') > -1) {
      this.setState({showAlerts:true,alertMessage:'La url donde se colocará la Nota de Prensa no debe tener caracteres en blanco'});
    } else if (!(/^([a-zA-Z0-9 _-]+)$/.test(pressnote.url))) {
      this.setState({showAlerts:true,alertMessage:'La url donde se colocará la Nota de Prensa tiene caracteres no adecuados. Utiliza a-z A-Z 0-9 _ -'});
    } else if (this.state.pressnotes.filter((item,index) => {
              return (item.url.toLowerCase().trim() === pressnote.url.toLowerCase().trim() && item.action !== pressnote.id);
            }).length > 0) {
      this.setState({showAlerts:true,alertMessage:'Ese url donde se colocará la Nota de Prensa ya está ocupada. Prueba con otra url.'});
    } else if (this.state.pressnotes.filter((item,index) => {
              return (item.pdf && pressnote.pdf && item.pdf.toLowerCase().trim() === pressnote.pdf.toLowerCase().trim() && item.action !== pressnote.id);
            }).length > 0) {
      this.setState({showAlerts:true,alertMessage:'Ese nombre de fichero PDF de la Nota de Prensa ya está ocupada. Prueba con otro fichero.'});
    } else if (!pressnote.date) {
      this.setState({showAlerts:true,alertMessage:'Elige una fecha para la Nota de Prensa.'});
    } else {

      this.setState({loading : true});
      let tempContent= pressnote.content;
      tempContent = tempContent.replace(/\&ldquo;|\&rdquo;/g,'"');
      tempContent = tempContent.replace(/\&rsquo;/g,'\'');
      pressnote.content = tempContent.replace(/\<br \/\>\<br \/\>/g,'<br>&nbsp;<br>');
      console.log(pressnote.id);
      if (pressnote.id == 0) {
        console.log("entro");
        pressnote.author = this.state.username;
      }

      this.insertNewPressNote(pressnote)
        .then(res => {
          console.log(res);
          let pressnotes = this.state.pressnotes;
          console.log(pressnote);
          if (pressnote.id > 0) {
            if (pdf != null) {
              this.insertNewPdf(res,pdf);
            }
            pressnotes.map((item,index) => {
              if (item.action === pressnote.id) {
                const datenow = new Date();
                item.lastUpdate = datenow.getFullYear() + "-" + ("0"+(datenow.getMonth()+1)).slice(-2) + "-" + ("0" + datenow.getDate()).slice(-2) + " " + ("0" + datenow.getHours()).slice(-2) + ":" + ("0" + datenow.getMinutes()).slice(-2) + ":" + ("0" + datenow.getSeconds()).slice(-2);
                item.titulo = pressnote.titulo;
                item.url = pressnote.url;
                item.description = pressnote.description;
                item.keywords = pressnote.keywords;
                item.pdf = pressnote.pdf;
                let notedate = new Date(pressnote.date);
                item.date = notedate.getFullYear() + "-" + ("0"+(notedate.getMonth()+1)).slice(-2) + "-" + ("0" + notedate.getDate()).slice(-2);
                item.visible = 0;
              };
            });
          } else {
            console.log("es un add: "+res);
              if (pdf != null) {
                this.insertNewPdf(res,pdf);
              }
                let item = {};
                item.action = res;
                item.titulo = pressnote.titulo;
                const datenow = new Date();
                item.lastUpdate = datenow.getFullYear() + "-" + ("0"+(datenow.getMonth()+1)).slice(-2) + "-" + ("0" + datenow.getDate()).slice(-2) + " " + ("0" + datenow.getHours()).slice(-2) + ":" + ("0" + datenow.getMinutes()).slice(-2) + ":" + ("0" + datenow.getSeconds()).slice(-2);
                item.author = pressnote.author;
                item.url = pressnote.url;
                item.description = pressnote.description;
                item.keywords = pressnote.keywords;
                item.pdf = pressnote.pdf;
                let notedate = new Date(pressnote.date);
                item.date = notedate.getFullYear() + "-" + ("0"+(notedate.getMonth()+1)).slice(-2) + "-" + ("0" + notedate.getDate()).slice(-2);
                item.visible = 0;
                pressnotes.push(item);
          }
          console.log(pressnotes);
          this.setState({pressnotes:pressnotes,showEditPressNote:false,showAddPressNote:false,showClonePressNote:false,loading : false});
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  insertNewPressNote = (content) => {
    console.log(content);
    content.username = this.state.username;
    return new Promise((resolve, reject) => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/pressnotes?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/pressnotes';
      fetch(urlServer, {
        method: 'POST',
        body: JSON.stringify(content),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .then(json => {
          console.log(json);
          if (json.Response === 'True') {
            console.log(json.newid);
            resolve(json.newid);
          } else {
            reject(json.Error);
          }
        })
        .catch(err => {
          console.log(err);
          reject('api error');
        });
        //resolve(content.id);
    });
  }

  insertNewPdf = (id,pdf) => {
    console.log(id);
    console.log(pdf);
    return new Promise((resolve, reject) => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/addpdf?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/addpdf';
      let formdata = new FormData();
      formdata.append('file', pdf);

      fetch(urlServer, {
        method: 'POST',
        body: formdata
      }).then(res => {
        resolve(res);
      }).catch(err => {
        console.log(err);
        reject('api error');
      });
    });
  }

  showPreview = (content) => {
    console.log(content);
    this.setState({preview:content});
  }

  getEditPressNote = (value) => {
    this.setState({loading : true});
    this.getPressNoteData(value)
      .then(res => {
        this.setState({pressnoteSelected:res[0],showEditPressNote:true,loading : false});
      })
      .catch(error => {
        console.log(error);
      });
  }

  getPressNoteData = (opts) => {
    console.log(opts);
    return new Promise((resolve, reject) => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/pressnotes?t='+ timestamp + '&k=' + authParam + '&id=' + opts;
      //let urlServer = this.props.serverRoute + '/pressnotes?id='+opts;
      fetch(urlServer)
        .then(res => res.json())
        .then(json => {
          if (json.Response === 'True') {
            console.log(json);
            resolve(json.data);
          } else {
            reject(json.Error);
          }
        })
        .catch(err => {
          console.log(err);
          reject('api error');
        });

        //resolve([customDataPressNote]);
    });
  }

  showClonePressNote = (value) => {
    console.log(value);
    this.setState({loading : true});
    this.getPressNoteData(value)
      .then(res => {
        res[0].id = 0;
        res[0].titulo = '';
        res[0].url ='';
        res[0].date ='';
        res[0].pdf = null;
        this.setState({pressnoteSelected:res[0],showClonePressNote:true,loading : false});
      })
      .catch(error => {
        console.log(error);
      });
  }

  showDeletePressNote = (value) => {
    this.setState({pressnoteToDeleted:value,showDeletePressNote:true});
  }

  deletePressNote = (value) => {
    console.log(value);

    return new Promise((resolve, reject) => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/pressnotes?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/pressnotes';
      fetch(urlServer, {
        method: 'DELETE',
        body: JSON.stringify(value),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .then(json => {
          console.log(json);
          if (json.Response === 'True') {
            let pressnotes = this.state.pressnotes;
            let filtered = pressnotes.filter((item,index) => {
              return item.action !== value.action;
            });
            console.log(filtered);
            resolve();
            this.setState({pressnotes:filtered,showDeletePressNote:false,pressnoteToDeleted:null});
          } else {
            reject(json.Error);
            this.setState({showDeletePressNote:false,pressnoteToDeleted:null});
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({showDeletePressNote:false,pressnoteToDeleted:null});
          reject('api error');
        });
    });
  }

  publishPressNote = (value) => {
    console.log("publish: " + value);
    this.setState({loading:true});

    return new Promise((resolve, reject) => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/publish-pressnote?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/publish-pressnote';
      fetch(urlServer, {
        method: 'POST',
        body: JSON.stringify({id:value}),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .then(json => {
          console.log(json);
          if (json.Response === 'True') {
            let pressnotes = this.state.pressnotes;
            pressnotes.map((item,index) => {
              if (item.action === value) {
                item.visible = 1;
              }
            });
            resolve();
            this.setState({pressnotes:pressnotes,loading:false});
          } else {
            reject(json.Error);
            this.setState({loading:false});
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({loading:false});
          reject('api error');
        });
    });
  }

  unpublishPressNote = (value) => {
    console.log("unpublish: " + value);
    this.setState({loading:true});

    return new Promise((resolve, reject) => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/unpublish-pressnote?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/unpublish-pressnote';
      fetch(urlServer, {
        method: 'POST',
        body: JSON.stringify({id:value}),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .then(json => {
          console.log(json);
          if (json.Response === 'True') {
            let pressnotes = this.state.pressnotes;
            pressnotes.map((item,index) => {
              if (item.action === value) {
                item.visible = 0;
              }
            });
            resolve();
            this.setState({pressnotes:pressnotes,loading:false});
          } else {
            reject(json.Error);
            this.setState({loading:false});
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({loading:false});
          reject('api error');
        });
    });
  }

  correctLogin = (username) => {
    this.setState({username:username,loading:true});
    sessionStorage.setItem('username',username);
    this.getPressNotes();
  }

  render() {
    console.log(this.state.pressnotes);
    let listPressNotes = null;
    if (this.state.orderDir === "desc") {
      listPressNotes = this.state.pressnotes.filter((item,index) => {
        /*return (item.titulo.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.lastUpdate.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
          item.referencia.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.categoria.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
          item.unidad.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.url.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1);*/
          return (item.titulo.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.lastUpdate.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
          item.url.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || (item.pdf != null && item.pdf.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) || item.date.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1);
      }).sort((a, b) => String(b[this.state.orderField]).localeCompare(a[this.state.orderField])).map((item, index) => (
        <ListPressNotes key={index} index={index} pressnote={item} edita_cont={(value) => this.getEditPressNote(value)} borrar_cont={(value) => this.showDeletePressNote(value)} clonar_cont={(value) => this.showClonePressNote(value)} publish_cont={(value) => this.publishPressNote(value)} unpublish_cont={(value) => this.unpublishPressNote(value)} />
      ));
    } else {
      listPressNotes = this.state.pressnotes.filter((item,index) => {
        /*return (item.titulo.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.lastUpdate.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
          item.referencia.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.categoria.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
          item.unidad.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.url.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1);*/
          return (item.titulo.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.lastUpdate.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
          item.url.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || (item.pdf != null && item.pdf.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) || item.lastUpdate.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1);
      }).sort((a, b) => String(a[this.state.orderField]).localeCompare(b[this.state.orderField])).map((item, index) => (
        <ListPressNotes key={index} index={index} pressnote={item} edita_cont={(value) => this.getEditPressNote(value)} borrar_cont={(value) => this.showDeletePressnote(value)} clonar_cont={(value) => this.showClonePressNote(value)} publish_cont={(value) => this.publishPressNote(value)} unpublish_cont={(value) => this.unpublishPressNote(value)} />
      ));
    }
    return (
      <>
      {this.state.username === '' ? (
        <Login correctUser={(username) => this.correctLogin(username)} serverRoute={this.props.serverRoute} />
      ) : (
        <>
        <div className="header-buttons">
          <div className="form-search">
            <input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearch} />
            <span className="total-results-search">{listPressNotes.length} artículos encontrados</span>
          </div>
          <div className="button-add-contract" onClick={() => this.showAddPressNote()}>Crear nueva Nota de Prensa</div>
        </div>
        <ListPressNotesHeader orderField={this.state.orderField} orderDir={this.state.orderDir} changeOrder={(order,previousOrder) => this.changeOrder(order,previousOrder)} />
        <div className="content-resultrows">
          {listPressNotes}
        </div>
        {this.state.loading ? (
          <Loading />
          ) : null
        }
        {this.state.showAddPressNote ? (
          <AddPressNote closeAddPressNote={() => this.setState({showAddPressNote:false})} showPreview={(content) => this.showPreview(content)} savePressNote={(content,pdf) => this.savePressNote(content,pdf)} />
          ) : null
        }
        {this.state.preview ? (
          <PreviewPressNote closePreviewPressNote={() => this.setState({preview:null})} content={this.state.preview} />
        ) : null}
        {this.state.showEditPressNote ? (
          <EditPressNote serverRoute={this.props.serverRoute} pressnote={this.state.pressnoteSelected} closeEditPressNote={() => this.setState({showEditPressNote:false})} showPreview={(content) => this.showPreview(content)} savePressNote={(content,pdf) => this.savePressNote(content,pdf)} />
          ) : null
        }
        {this.state.showClonePressNote ? (
          <ClonePressNote pressnote={this.state.pressnoteSelected} closeClonePressNote={() => this.setState({showClonePressNote:false})} showPreview={(content) => this.showPreview(content)} savePressNote={(content,pdf) => this.savePressNote(content,pdf)} />
          ) : null
        }
        {this.state.showDeletePressNote ? (
          <DeletePressNote pressnote={this.state.pressnoteToDeleted} closeBorrarPressNote={() => this.setState({showDeletePressNote:false,pressnoteToDeleted:null})} deletePressNote={(value) => this.deletePressNote(value)} />
          ) : null
        }
        {this.state.showAlerts ? (
          <Alerts message={this.state.alertMessage} closeAlert={() => this.setState({showAlerts:false,alertMessage:''})} />
          ) : null
        }
        </>
      )}
      </>
    );
  }


}

export default App;
