import React from 'react';

const ListContratsHeader = ({orderField, orderDir, changeOrder}) => {
    return (
      <div className="resultsrow header">
        <div className="resultsrow-in">
          <div className="resultsrow-column link" onClick={() => changeOrder('lastUpdate',orderField)}>
            <div className="resultsrow-title">Último cambio 
              {orderField === 'lastUpdate' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'lastUpdate' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column link" onClick={() => changeOrder('titulo',orderField)}>
            <div className="resultsrow-title">Título 
              {orderField === 'titulo' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'titulo' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column link" onClick={() => changeOrder('referencia',orderField)}>
            <div className="resultsrow-title">Referencia 
              {orderField === 'referencia' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'referencia' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>            
          </div>
          <div className="resultsrow-column link" onClick={() => changeOrder('categoria',orderField)}>
            <div className="resultsrow-title">Categoría 
              {orderField === 'categoria' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'categoria' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>            
          </div>
          <div className="resultsrow-column link" onClick={() => changeOrder('visible',orderField)}>
            <div className="resultsrow-title">Publicado 
              {orderField === 'visible' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'visible' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column link" onClick={() => changeOrder('unidad',orderField)}>
            <div className="resultsrow-title">Unidad 
              {orderField === 'unidad' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'unidad' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column link" onClick={() => changeOrder('url',orderField)}>
            <div className="resultsrow-title">URL 
              {orderField === 'url' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'url' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column">
            <div className="resultsrow-title center">Acciones</div>            
          </div>
        </div>
      </div>
    );

}

export default ListContratsHeader;
