import React from 'react';
import './styles.css';

const ListDictionary = ({ index, page, add_words }) => {
    return (
      <div className={index % 2 === 0 ? "resultsrow pair" : "resultsrow"}>
        <div className="resultsrow-in">
          <div className="resultsrow-column dictionary">
            <div className="resultsrow-title">{page.url}</div>
          </div>
          <div className="resultsrow-column dictionary">
            <div className="resultsrow-title" dangerouslySetInnerHTML = { { __html: page.title } }></div>
          </div>
          <div className="resultsrow-column dictionary">
            <div className="resultsrow-title" dangerouslySetInnerHTML = { { __html: page.text } }></div>
          </div>
          <div className="resultsrow-column dictionary">
            <div className="resultsrow-title added" dangerouslySetInnerHTML = { { __html: page.added } }></div>
            <svg viewBox="0 0 24 24" onClick={() => add_words(page.url)}>
              <path d="M17,13H13V17H11V13H7V11H11V7H13V11H17M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
            </svg>
          </div>
        </div>
      </div>
    );

}

export default ListDictionary;
