import React, { Component } from 'react';
import ListJobOffers from './ListJobOffers';
import ListJobOffersHeader from './ListJobOffersHeader';
import AddJobOffer from './AddJobOffer';
import EditJobOffer from './EditJobOffer';
import CloneJobOffer from './CloneJobOffer';
import DeleteJobOffer from './DeleteJobOffer';
import PreviewJobOffer from './PreviewJobOffer';
import Loading from '../Loading';
import Login from '../Login';
import Alerts from '../Alerts';
import {authParams} from '../Lib/functions';

class App extends Component {
    constructor(props) {
      super(props);
      console.log(this.props.username);
      console.log(this.props.serverRoute);
      let username = this.props.username;
      if (sessionStorage.getItem('username')) {
        username = sessionStorage.getItem('username');
      }
      this.state = {
        joboffers:[],
        loading: false,
        orderField: 'lastUpdate',
        orderDir: 'desc',
        search: '',
        username:username,
        showAddJobOffer: false,
        showEditJobOffer: false,
        showDeleteJobOffer: false,
        showCloneJobOffer: false,
        jobofferSelected: null,
        jobofferToDeleted: null,
        showAlerts: false,
        preview: null,
      }
    }
  
    componentDidMount = () => {
      if (this.props.username || sessionStorage.getItem('username')) {
        this.setState({loading:true});
        this.getJobOffers();
      }
    }

    getJobOffers = () => {
        this.setState({loading : true});
        this.getJobOffersCall()
          .then(res => {
            const joboffers = [];
            console.log(res);
            res.data.forEach(item => {
              joboffers.push(item);
            })
            this.setState({joboffers:joboffers,loading : false});
        })
        .catch(error => {
            alert(error);
            this.setState({joboffers:[],loading : false});
        });
    }

    getJobOffersCall = (obj = {}) => {
        return new Promise((resolve, reject) => {
          
          var timestamp = new Date().getTime();
          let authParam = authParams(timestamp);
          let urlServer = this.props.serverRoute + '/joboffers?t='+ timestamp + '&k=' + authParam;
          //let urlServer = this.props.serverRoute + '/joboffers';
          console.log(urlServer);
          fetch(urlServer)
            .then(res => res.json())
            .then(json => {
              if (json.Response === 'True') {
                resolve(json);
              } else {
                reject(json.Error);
              }
            })
            .catch(err => {
              console.log(err);
              reject('api error');
            });
            
            //resolve(customData);
        });
    }

    handleSearch = (event) => {
        this.setState({search:event.target.value});
    }

    showAddJobOffer = () => {
        this.setState({showAddJobOffer:true});
    }
      
    changeOrder = (order, previousOrder) => {
        console.log(order+' - '+previousOrder);
        let orderDir = this.state.orderDir;
        if (order === previousOrder) {
          if (orderDir === "asc") {
            orderDir = "desc";
          } else {
            orderDir = "asc";
          }
        } else {
            orderDir = "asc";
        }
        this.setState({orderDir:orderDir,orderField:order});
    }

    saveJobOffer = (joboffer, pdf=null) => {
      console.log(pdf);
      if (joboffer.url.trim() === '') {
        this.setState({showAlerts:true,alertMessage:'Añade la url donde se colocará la Oferta de Empleo.'});
      } else if (joboffer.url.indexOf(' ') > -1) {
        this.setState({showAlerts:true,alertMessage:'La url donde se colocará la Oferta de Empleo no debe tener caracteres en blanco'});
      } else if (!(/^([a-zA-Z0-9 _-]+)$/.test(joboffer.url))) {
        this.setState({showAlerts:true,alertMessage:'La url donde se colocará la Oferta de Empleo tiene caracteres no adecuados. Utiliza a-z A-Z 0-9 _ -'});
      } else if (this.state.joboffers.filter((item,index) => {
                return (item.url.toLowerCase().trim() === joboffer.url.toLowerCase().trim() && item.action !== joboffer.id);
              }).length > 0) {
        this.setState({showAlerts:true,alertMessage:'Ese url donde se colocará la Oferta de Empleo ya está ocupada. Prueba con otra url.'});
      } else if (this.state.joboffers.filter((item,index) => {
                return (item.pdf && joboffer.pdf && item.pdf.toLowerCase().trim() === joboffer.pdf.toLowerCase().trim() && item.action !== joboffer.id);
              }).length > 0) {
        this.setState({showAlerts:true,alertMessage:'Ese nombre de fichero PDF de la Oferta de Empleo ya está ocupada. Prueba con otro fichero.'});
      } else if (!joboffer.date) {
        this.setState({showAlerts:true,alertMessage:'Elige una fecha para la Oferta de Empleo.'});
      } else {
  
        this.setState({loading : true});
        let tempContent= joboffer.content;
        tempContent = tempContent.replace(/\&ldquo;|\&rdquo;/g,'"');
        tempContent = tempContent.replace(/\&rsquo;/g,'\'');
        joboffer.content = tempContent.replace(/\<br \/\>\<br \/\>/g,'<br>&nbsp;<br>');
        console.log(joboffer.id);
        if (joboffer.id == 0) {
          console.log("entro");
          joboffer.author = this.state.username;
        }
  
        this.insertNewJobOffer(joboffer)
          .then(res => {
            console.log(res);
            let joboffers = this.state.joboffers;
            console.log(joboffer);
            if (joboffer.id > 0) {
              if (pdf != null) {
                this.insertNewPdf(res,pdf);
              }
              joboffers.map((item,index) => {
                if (item.action === joboffer.id) {
                  const datenow = new Date();
                  item.lastUpdate = datenow.getFullYear() + "-" + ("0"+(datenow.getMonth()+1)).slice(-2) + "-" + ("0" + datenow.getDate()).slice(-2) + " " + ("0" + datenow.getHours()).slice(-2) + ":" + ("0" + datenow.getMinutes()).slice(-2) + ":" + ("0" + datenow.getSeconds()).slice(-2);
                  item.titulo = joboffer.titulo;
                  item.url = joboffer.url;
                  item.description = joboffer.description;
                  item.keywords = joboffer.keywords;
                  item.pdf = joboffer.pdf;
                  let notedate = new Date(joboffer.date);
                  item.date = notedate.getFullYear() + "-" + ("0"+(notedate.getMonth()+1)).slice(-2) + "-" + ("0" + notedate.getDate()).slice(-2);
                  item.visible = 0;
                };
              });
            } else {
              console.log("es un add: "+res);
                if (pdf != null) {
                  this.insertNewPdf(res,pdf);
                }
                  let item = {};
                  item.action = res;
                  item.titulo = joboffer.titulo;
                  const datenow = new Date();
                  item.lastUpdate = datenow.getFullYear() + "-" + ("0"+(datenow.getMonth()+1)).slice(-2) + "-" + ("0" + datenow.getDate()).slice(-2) + " " + ("0" + datenow.getHours()).slice(-2) + ":" + ("0" + datenow.getMinutes()).slice(-2) + ":" + ("0" + datenow.getSeconds()).slice(-2);
                  item.author = joboffer.author;
                  item.url = joboffer.url;
                  item.description = joboffer.description;
                  item.keywords = joboffer.keywords;
                  item.pdf = joboffer.pdf;
                  let notedate = new Date(joboffer.date);
                  item.date = notedate.getFullYear() + "-" + ("0"+(notedate.getMonth()+1)).slice(-2) + "-" + ("0" + notedate.getDate()).slice(-2);
                  item.visible = 0;
                  joboffers.push(item);
            }
            console.log(joboffers);
            this.setState({joboffers:joboffers,showEditJobOffer:false,showAddJobOffer:false,showCloneJobOffer:false,loading : false});
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  
    insertNewJobOffer = (content) => {
      console.log(content);
      content.username = this.state.username;
      return new Promise((resolve, reject) => {
        var timestamp = new Date().getTime();
        let authParam = authParams(timestamp);
        let urlServer = this.props.serverRoute + '/joboffers?t='+ timestamp + '&k=' + authParam;
        //let urlServer = this.props.serverRoute + '/joboffers';
        fetch(urlServer, {
          method: 'POST',
          body: JSON.stringify(content),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
          .then(json => {
            console.log(json);
            if (json.Response === 'True') {
              console.log(json.newid);
              resolve(json.newid);
            } else {
              reject(json.Error);
            }
          })
          .catch(err => {
            console.log(err);
            reject('api error');
          });
          //resolve(content.id);
      });
    }
  
    insertNewPdf = (id,pdf) => {
      console.log(id);
      console.log(pdf);
      return new Promise((resolve, reject) => {
        var timestamp = new Date().getTime();
        let authParam = authParams(timestamp);
        let urlServer = this.props.serverRoute + '/addpdf-jo?t='+ timestamp + '&k=' + authParam;
        //let urlServer = this.props.serverRoute + '/addpdf-jo';
        let formdata = new FormData();
        formdata.append('file', pdf);
  
        fetch(urlServer, {
          method: 'POST',
          body: formdata
        }).then(res => {
          resolve(res);
        }).catch(err => {
          console.log(err);
          reject('api error');
        });
      });
    }

    showPreview = (content) => {
      console.log(content);
      this.setState({preview:content});
    }
    
    getEditJobOffer = (value) => {
      this.setState({loading : true});
      this.getJobOfferData(value)
        .then(res => {
          this.setState({jobofferSelected:res[0],showEditJobOffer:true,loading : false});
        })
        .catch(error => {
          console.log(error);
        });
    }
 
    getJobOfferData = (opts) => {
      console.log(opts);
      return new Promise((resolve, reject) => {
        var timestamp = new Date().getTime();
        let authParam = authParams(timestamp);
        let urlServer = this.props.serverRoute + '/joboffers?t='+ timestamp + '&k=' + authParam + '&id=' + opts;
        //let urlServer = this.props.serverRoute + '/joboffers?id='+opts;
        fetch(urlServer)
          .then(res => res.json())
          .then(json => {
            if (json.Response === 'True') {
              console.log(json);
              resolve(json.data);
            } else {
              reject(json.Error);
            }
          })
          .catch(err => {
            console.log(err);
            reject('api error');
          });
  
          //resolve([customDataPressNote]);
      });
    } 

    showCloneJobOffer = (value) => {
      console.log(value);
      this.setState({loading : true});
      this.getJobOfferData(value)
        .then(res => {
          res[0].id = 0;
          res[0].titulo = '';
          res[0].url ='';
          res[0].date ='';
          res[0].pdf = null;
          this.setState({jobofferSelected:res[0],showCloneJobOffer:true,loading : false});
        })
        .catch(error => {
          console.log(error);
        });
    }

    showDeleteJobOffer = (value) => {
      this.setState({jobofferToDeleted:value,showDeleteJobOffer:true});
    }
  
    deleteJobOffer = (value) => {
      console.log(value);
  
      return new Promise((resolve, reject) => {
        var timestamp = new Date().getTime();
        let authParam = authParams(timestamp);
        let urlServer = this.props.serverRoute + '/joboffers?t='+ timestamp + '&k=' + authParam;
        //let urlServer = this.props.serverRoute + '/joboffers';
        fetch(urlServer, {
          method: 'DELETE',
          body: JSON.stringify(value),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
          .then(json => {
            console.log(json);
            if (json.Response === 'True') {
              let joboffers = this.state.joboffers;
              let filtered = joboffers.filter((item,index) => {
                return item.action !== value.action;
              });
              console.log(filtered);
              resolve();
              this.setState({joboffers:filtered,showDeleteJobOffer:false,jobofferToDeleted:null});
            } else {
              reject(json.Error);
              this.setState({showDeleteJobOffer:false,jobofferToDeleted:null});
            }
          })
          .catch(err => {
            console.log(err);
            this.setState({showDeleteJobOffer:false,jobofferToDeleted:null});
            reject('api error');
          });
      });
    }

    publishJobOffer = (value) => {
      console.log("publish: " + value);
      this.setState({loading:true});
  
      return new Promise((resolve, reject) => {
        var timestamp = new Date().getTime();
        let authParam = authParams(timestamp);
        let urlServer = this.props.serverRoute + '/publish-joboffer?t='+ timestamp + '&k=' + authParam;
        //let urlServer = this.props.serverRoute + '/publish-joboffer';
        fetch(urlServer, {
          method: 'POST',
          body: JSON.stringify({id:value}),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
          .then(json => {
            console.log(json);
            if (json.Response === 'True') {
              let joboffers = this.state.joboffers;
              joboffers.map((item,index) => {
                if (item.action === value) {
                  item.visible = 1;
                }
              });
              resolve();
              this.setState({joboffers:joboffers,loading:false});
            } else {
              reject(json.Error);
              this.setState({loading:false});
            }
          })
          .catch(err => {
            console.log(err);
            this.setState({loading:false});
            reject('api error');
          });
      });
    }
  
    unpublishJobOffer = (value) => {
      console.log("unpublish: " + value);
      this.setState({loading:true});
  
      return new Promise((resolve, reject) => {
        var timestamp = new Date().getTime();
        let authParam = authParams(timestamp);
        let urlServer = this.props.serverRoute + '/unpublish-joboffer?t='+ timestamp + '&k=' + authParam;
        //let urlServer = this.props.serverRoute + '/unpublish-joboffer';
        fetch(urlServer, {
          method: 'POST',
          body: JSON.stringify({id:value}),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
          .then(json => {
            console.log(json);
            if (json.Response === 'True') {
              let joboffers = this.state.joboffers;
              joboffers.map((item,index) => {
                if (item.action === value) {
                  item.visible = 0;
                }
              });
              resolve();
              this.setState({joboffers:joboffers,loading:false});
            } else {
              reject(json.Error);
              this.setState({loading:false});
            }
          })
          .catch(err => {
            console.log(err);
            this.setState({loading:false});
            reject('api error');
          });
      });
    }

    correctLogin = (username) => {
        this.setState({username:username,loading:true});
        sessionStorage.setItem('username',username);
        this.getJobOffers();
    }
    
    render() {
        console.log(this.state.joboffers);
        let listJobOffers = null;
        if (this.state.orderDir === "desc") {
            listJobOffers = this.state.joboffers.filter((item,index) => {
              return (item.titulo.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.lastUpdate.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
              item.url.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || (item.pdf != null && item.pdf.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) || item.date.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1);
          }).sort((a, b) => String(b[this.state.orderField]).localeCompare(a[this.state.orderField])).map((item, index) => (
            <ListJobOffers key={index} index={index} joboffer={item} edita_cont={(value) => this.getEditJobOffer(value)} borrar_cont={(value) => this.showDeleteJobOffer(value)} clonar_cont={(value) => this.showCloneJobOffer(value)} publish_cont={(value) => this.publishJobOffer(value)} unpublish_cont={(value) => this.unpublishJobOffer(value)} />
          ));
        } else {
            listJobOffers = this.state.joboffers.filter((item,index) => {
              return (item.titulo.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.lastUpdate.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
              item.url.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || (item.pdf != null && item.pdf.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) || item.lastUpdate.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1);
          }).sort((a, b) => String(a[this.state.orderField]).localeCompare(b[this.state.orderField])).map((item, index) => (
            <ListJobOffers key={index} index={index} joboffer={item} edita_cont={(value) => this.getEditJobOffer(value)} borrar_cont={(value) => this.showDeleteJobOffer(value)} clonar_cont={(value) => this.showCloneJobOffer(value)} publish_cont={(value) => this.publishJobOffer(value)} unpublish_cont={(value) => this.unpublishJobOffer(value)} />
          ));
        }
        return (
          <>
          {this.state.username === '' ? (
            <Login correctUser={(username) => this.correctLogin(username)} serverRoute={this.props.serverRoute} />
          ) : (
            <>
            <div className="header-buttons">
              <div className="form-search">
                <input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearch} />
                <span className="total-results-search">{listJobOffers.length} artículos encontrados</span>
              </div>
              <div className="button-add-contract" onClick={() => this.showAddJobOffer()}>Crear nueva Oferta de Empleo</div>
            </div>
            <ListJobOffersHeader orderField={this.state.orderField} orderDir={this.state.orderDir} changeOrder={(order,previousOrder) => this.changeOrder(order,previousOrder)} />
            <div className="content-resultrows">
              {listJobOffers}
            </div>
            {this.state.loading ? (
              <Loading />
              ) : null
            }
            {this.state.showAddJobOffer ? (
              <AddJobOffer closeAddJobOffer={() => this.setState({showAddJobOffer:false})} showPreview={(content) => this.showPreview(content)} saveJobOffer={(content,pdf) => this.saveJobOffer(content,pdf)} />
              ) : null
            }
            {this.state.preview ? (
              <PreviewJobOffer closePreviewJobOffer={() => this.setState({preview:null})} content={this.state.preview} />
            ) : null}
            {this.state.showEditJobOffer ? (
              <EditJobOffer serverRoute={this.props.serverRoute} joboffer={this.state.jobofferSelected} closeEditJobOffer={() => this.setState({showEditJobOffer:false})} showPreview={(content) => this.showPreview(content)} saveJobOffer={(content,pdf) => this.saveJobOffer(content,pdf)} />
              ) : null
            }
            {this.state.showCloneJobOffer ? (
              <CloneJobOffer joboffer={this.state.jobofferSelected} closeCloneJobOffer={() => this.setState({showCloneJobOffer:false})} showPreview={(content) => this.showPreview(content)} saveJobOffer={(content,pdf) => this.saveJobOffer(content,pdf)} />
              ) : null
            }
            {this.state.showDeleteJobOffer ? (
              <DeleteJobOffer joboffer={this.state.jobofferToDeleted} closeBorrarJobOffer={() => this.setState({showDeleteJobOffer:false,jobofferToDeleted:null})} deleteJobOffer={(value) => this.deleteJobOffer(value)} />
              ) : null
            }
            {this.state.showAlerts ? (
              <Alerts message={this.state.alertMessage} closeAlert={() => this.setState({showAlerts:false,alertMessage:''})} />
              ) : null
            }
            </>
          )}
          </>
        );
    }
}
    
export default App;
