import React from 'react';
import "../../terms.css";

const PreviewJobOfferArsys = ({ content }) => {
	let jobofferdate = new Date(content.date);
    return (
    	<>
			<link rel="stylesheet" type="text/css" href="https://www.arsys.es/get/css/terms.css" media="screen" />
			<header className="previewshow">
				<div className="width">
					<img src="https://www.arsys.es/img/shared/logo.png?=CV-1047" />
					<hr />
					<a className="posttitle" title="Trabajemos juntos">Empleo</a>
					<p className="ref_contrato" dangerouslySetInnerHTML={{__html: "["+(jobofferdate.getDate() < 10 ? "0" + jobofferdate.getDate() : jobofferdate.getDate()) + '/' + (jobofferdate.getMonth() < 9 ? "0" + (jobofferdate.getMonth() + 1) : jobofferdate.getMonth() + 1) + '/' + jobofferdate.getFullYear()+"]"}}></p>
				</div>
			</header>
			<main className="previewshow">
				<div className="width">
					<h1 dangerouslySetInnerHTML={{__html: content.titulo}}></h1>
					<div>
						{content.pdf && content.pdf !== '' ? <p><a className="desDownload" href="#" title="Descargar Documento">Descargar documento</a></p>:''}
						<div dangerouslySetInnerHTML={{__html: content.content.replace(/<a /g, '<a target="_blank" ')}}></div>
					</div>
				</div>
			</main>
			<footer className="previewshow">
				<div className="width">
					<img src="https://www.arsys.es/img/shared/logo.png?=CV-1047" />
					<p className="copyright">Copyright © Arsys</p>
				</div>
			</footer>
		</>
    );

}

export default PreviewJobOfferArsys;