import React from 'react';
import './styles.css';

const DeleteJobOffer = ({ joboffer, closeBorrarJobOffer, deleteJobOffer }) => {
    return (
      <div className="borrar-contrat">
        <div className="borrar-contrat-in">
          <div className="borrar-contrat-in-title">¿Estás seguro de que quieres borrar esta Oferta de Empleo?</div>
          <div className="borrar-contrat-in-buttons">
            <div className="borrar-contrat-in-button-yes" onClick={() => deleteJobOffer(joboffer)}>Sí</div>
            <div className="borrar-contrat-in-button-no" onClick={() => closeBorrarJobOffer()}>No</div>
          </div>
        </div>
      </div>
    );

}

export default DeleteJobOffer;
