import React from 'react';
import PreviewJobOfferArsys from './PreviewJobOfferArsys';
import './styles.css';

const PreviewJobOffer = ({ closePreviewJobOffer, content }) => {
    return (
        <div className="preview-pressnote">
        <div className="preview-pressnote-in">
          <div className="preview-pressnote-close" onClick={() => closePreviewJobOffer()}></div>
          <div className="preview-pressnote-content">
            <PreviewJobOfferArsys content={content} />
          </div>
		</div>
        </div>
    );

}

export default PreviewJobOffer;