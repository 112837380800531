import React, { Component } from 'react';
import Login from './Login';
import PressNotes from './PressNotes/App';
import Contracts from './Contracts/App';
import JobOffers from './JobOffers/App';
import WebSearch from './WebSearch/App';
import Alerts from './Alerts';
import Header from './Header';
import './App.css';

const serverRoute = 'https://node.devos.io:3001';
//const serverRoute = 'http://localhost:3001';

class App extends Component {
  constructor(props) {
    super(props);
    let username = '';
    let usertype = 0;
    let userview = 0;
    if (sessionStorage.getItem('username')) {
      username = sessionStorage.getItem('username');
    }
    if (sessionStorage.getItem('usertype')) {
      usertype = sessionStorage.getItem('usertype');
    }
    if (sessionStorage.getItem('userview')) {
      userview = sessionStorage.getItem('userview');
    }
    this.state = {
      loading: false,
      username:username,
      showAlerts: false,
      usertype:usertype,
      userview:userview
    }
  }

  componentDidMount = () => {
    if (sessionStorage.getItem('username')) {
      this.setState({loading:true});
    }
  }

  correctLogin = (username) => {
    //console.log(username);
    let userview = username.type === 1 ? 2:username.type;
    this.setState({username:username.username,usertype:username.type,userview:userview,loading:true});
    sessionStorage.setItem('username',username.username);
    sessionStorage.setItem('usertype',username.type);
    sessionStorage.setItem('userview',userview);
  }

  render() {
    console.log(serverRoute);
    console.log(this.state.usertype);
    return (
      <div className="App">
      {this.state.username === '' ? (
        <Login correctUser={(username) => this.correctLogin(username)} serverRoute={serverRoute} />
      ) : (
        <>
        <Header 
          username={this.state.username} 
          usertype={this.state.usertype} 
          userview={this.state.userview} 
          closeSession={()=>{this.setState({username:'',usertype:0,userview:0});sessionStorage.setItem('username','');sessionStorage.setItem('usertype',0);sessionStorage.setItem('userview',0)}} 
          changeUserView={(userview) => {this.setState({userview:userview});sessionStorage.setItem('userview',userview);}} 
        />
        {this.state.userview == '2' ?
        <Contracts username={this.state.username} serverRoute={serverRoute} />
        :
        this.state.userview == '3' ?
        <PressNotes username={this.state.username} serverRoute={serverRoute} /> 
        :
        this.state.userview == '4' ?
        <JobOffers username={this.state.username} serverRoute={serverRoute} />
        :
        <WebSearch username={this.state.username} serverRoute={serverRoute} />
        }
        {this.state.showAlerts ? (
          <Alerts message={this.state.alertMessage} closeAlert={() => this.setState({showAlerts:false,alertMessage:''})} />
          ) : null
        }
        </>
      )}
      </div>
    );
  }


}

export default App;
