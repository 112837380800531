import React from 'react';
import './styles.css';

const SitesTabs = ({ site, selectSite }) => {
    return (
        <div className="header-tabs">
            <div className={site === 'es' ? "header-tab selected" : "header-tab"} onClick={() => selectSite('es')}>.es<span>(web)</span></div>
            <div className={site === 'net' ? "header-tab selected" : "header-tab"} onClick={() => selectSite('net')}>.net<span>(web)</span></div>
            <div className={site === 'pt' ? "header-tab selected" : "header-tab"} onClick={() => selectSite('pt')}>.pt<span>(web)</span></div>
            <div className={site === 'fr' ? "header-tab selected" : "header-tab"} onClick={() => selectSite('fr')}>.fr<span>(web)</span></div>
            <div className={site === 'acses' ? "header-tab selected" : "header-tab"} onClick={() => selectSite('acses')}>.es<span>(acs)</span></div>
            <div className={site === 'acsnet' ? "header-tab selected" : "header-tab"} onClick={() => selectSite('acsnet')}>.net<span>(acs)</span></div>
            <div className={site === 'acspt' ? "header-tab selected" : "header-tab"} onClick={() => selectSite('acspt')}>.pt<span>(acs)</span></div>
            <div className={site === 'acsfr' ? "header-tab selected" : "header-tab"} onClick={() => selectSite('acsfr')}>.fr<span>(acs)</span></div>
            <div className={site === 'ps' ? "header-tab selected" : "header-tab"} onClick={() => selectSite('ps')}>PS<span>&nbsp;</span></div>
        </div>
    );
}

export default SitesTabs;
