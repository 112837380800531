import React from 'react';
import './styles.css';

const DeleteContract = ({ contract, closeBorrarContract, deleteContract }) => {
    return (
      <div className="borrar-contrat">
        <div className="borrar-contrat-in">
          <div className="borrar-contrat-in-title">¿Estás seguro de que quieres borrar este contrato?</div>
          <div className="borrar-contrat-in-buttons">
            <div className="borrar-contrat-in-button-yes" onClick={() => deleteContract(contract)}>Sí</div>
            <div className="borrar-contrat-in-button-no" onClick={() => closeBorrarContract()}>No</div>
          </div>
        </div>
      </div>
    );

}

export default DeleteContract;
