import React from 'react';

const ListPressNotesHeader = ({orderField, orderDir, changeOrder}) => {
    return (
      <div className="resultsrow header">
        <div className="resultsrow-in">
          <div className="resultsrow-column pressnotes link" onClick={() => changeOrder('lastUpdate',orderField)}>
            <div className="resultsrow-title">Último cambio 
              {orderField === 'lastUpdate' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'lastUpdate' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column pressnotes link" onClick={() => changeOrder('titulo',orderField)}>
            <div className="resultsrow-title">Título 
              {orderField === 'titulo' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'titulo' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column pressnotes link" onClick={() => changeOrder('pdf',orderField)}>
            <div className="resultsrow-title">PDF 
              {orderField === 'pdf' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'pdf' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column pressnotes link" onClick={() => changeOrder('date',orderField)}>
            <div className="resultsrow-title">Fecha 
              {orderField === 'date' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'date' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column pressnotes link" onClick={() => changeOrder('visible',orderField)}>
            <div className="resultsrow-title">Publicado 
              {orderField === 'visible' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'visible' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column pressnotes link" onClick={() => changeOrder('url',orderField)}>
            <div className="resultsrow-title">URL 
              {orderField === 'url' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'url' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column pressnotes">
            <div className="resultsrow-title center">Acciones</div>            
          </div>
        </div>
      </div>
    );

}

export default ListPressNotesHeader;
