import React from 'react';

const PreviewContratPiensa = ({ content }) => {
    return (
    	<>
    	{/*<link href="https://www.piensasolutions.com/resource/style/piensa.css?v=PS-2085" rel="stylesheet" type="text/css" />*/}
    	<link href="https://www.piensasolutions.com/resource/style/terms.css?v=PS-2085" rel="stylesheet" type="text/css" />
		{/*
		<header className="head fixed" style={{left: "0px"}}>
	<div className="notify">
		<div className="center">
			<div className="note"></div>
		</div>
	</div>
	<div className="top">
		<div className="center">
			<ul id="icons">
				<li id="phone" title="Teléfono disponible de lunes a viernes de 09:00 a 14:00 y de 16:00 a 19:00" style={{display: "block"}}>
					<i className="icon-telefono"></i>&nbsp;&nbsp;902 26 88 26 | 941 29 31 21
				</li>
				<li id="blog" title="Blog">
					<i className="icon-blog"></i>&nbsp;&nbsp;BLOG
				</li>
				<li id="chat" title="Chat">
					<i className="icon-chat"></i>&nbsp;&nbsp;CHAT
				</li>
				<li id="login" title="Clientes">
					<i className="icon-area-cliente"></i>
				</li>
				<li id="cart" title="" className="off">
					<i className="icon-carrito"></i>
				</li>
			</ul>
		</div>
	</div>
	<div className="bottom">
		<div className="center">
			<a href="#" title="Piensa Solutions"><div id="head_logo"></div></a>
			<nav>
				<ul id="links">
					<div className="collapse">
						<li id="dominios">
							<a title="Dominios">DOMINIOS</a>
							<ul className="subMenu">
								<li>
									<a href="#">Registro de dominios</a>
								</li>
								<li>
									<a href="#">Protección de Dominio</a>
								</li>
							</ul>
						</li>				
						<li id="alojamiento-web"><a href="#" title="Hosting">HOSTING</a></li>
						<li id="crear-web">
							<a title="Crear Web">CREAR WEB</a>
							<ul className="subMenu">
								<li>
									<a href="#">Mi Página Web</a>
								</li>
								<li>
									<a href="#">Hosting Wordpress</a>
								</li>
								<li>
									<a href="#">Tiendas Online</a>
								</li>
							</ul>
						</li>
						<li id="correo"><a href="#" title="Correo">CORREO</a></li>		
						<li id="certificados-ssl"><a href="#" title="Certificados SSL">SSL</a></li>
						<li id="posicionamiento-seo"><a href="#" title="Posicionamiento SEO">SEO</a></li>
					</div>
				</ul>
			</nav>
			<div id="crear_block" className="panel">
				<div className="up_arrow"></div>
				<div className="box" id="mnu_crear_web">
					<ul>
						<li>
							<a href="#" title="Mi Página Web">Mi Página Web</a>
						</li>
						<li>
							<a href="#" title="Hosting Wordpress">Hosting Wordpress</a>
						</li>
						<li>
							<a href="#" title="Tiendas Online">Tiendas Online</a>
						</li>
					</ul>
				</div>
			</div>
			<div id="domain_block" className="panel">
				<div className="up_arrow"></div>
				<div className="box" id="mnu_domain">
					<ul>
						<li>
							<a href="#" title="Registro de Dominios">Registro de Dominios</a>
						</li>
						<li>
							<a href="#" title="Protección de Dominio">Protección de Dominio</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
		</header>*/}
		{/*<main>
			<div className="row graphic">
				<div className="bg-image">
					<div className="block w12">
						<h1>Avisos Legales:<br /><strong>nuestras políticas</strong></h1>
						<p>Consulta nuestras políticas de uso, contratos, condiciones promocionales...<br /><br /><strong>Queremos que estés informado.</strong></p>
					</div>
				</div>
			</div>
			<div className="row bc-cleargrey">
				<article className="block bc-white w12 company-data">	
					<header className="bc-orange"><h2 className="bc-darkgrey fc-white uppercase">DATOS DE LA EMPRESA</h2></header>
					<div className="content">
						<section className="f-left pre-icon">
							<div className="icon small check f-left"></div>
							<header className="f-left">
								<h3>DENOMINACIÓN SOCIAL</h3>
							</header>	
							<p className="f-left">Tesys Internet S.L.U.</p>
						</section>
						<section className="f-left pre-icon">
							<div className="icon small i-card f-left"></div>
							<header className="f-left">
								<h3>NIF</h3>
							</header>
							<p className="f-left">B-26309096</p>
						</section>
						<section className="f-left pre-icon">
							<div className="icon small send-mail f-left"></div>
							<header className="f-left"><h3>EMAIL</h3></header>
							<p className="f-left">info@piensasolutions.com</p>
						</section>
						<section className="f-left pre-icon">
							<div className="icon small building f-left"></div>
							<header className="f-left">
								<h3>DOMICILIO SOCIAL</h3>
							</header>	
							<p className="f-left">Circunde nº 16, Polígono Industrial La Portalada, Logroño (La Rioja) C.P. 26006</p>
						</section>
						
						<div className="clear-both"></div>
					</div>
					<div className="content">
						<p><strong>Datos de inscripción en el Registro Mercantil</strong>: Tesys Internet, S.L.U., se constituyó en Logroño, el 3 de Noviembre del año 2000, por Escritura Pública número 3584 ante el notario D. Eloy Menéndez-Santirso Suarez, del Ilustre Colegio de Burgos, inscrita en el Registro Mercantil de La Rioja en el tomo 477, libro 0, folio 76, sección 8, Hoja LO-7211.</p>
						<p>Tesys Internet, S.L.U. (en adelante, denominada por la marca Piensa Solutions), publica sus datos de empresa, así como los Contratos, Políticas y Condiciones que regirán el uso del presente sitio web y de todos sus servicios.</p>
					</div>
				</article>
			</div>
			<div className="row bc-cleargrey">
				<article className="block bc-white w12">
					<header className="bc-orange"><h2 className="bc-darkgrey fc-white uppercase">POLÍTICAS Y CONDICIONES</h2></header>
					<div className="content no-b-padding">

						<section className="f-left half pre-icon">
							<div className="icon medium i-card f-left"></div>
							<header><h3 className="legal-doc" href="#">PROTECCIÓN DE DATOS DE CARACTER PERSONAL</h3></header>	
							<p>Consulta nuestra <strong>Política</strong> para conocer el tratamiento que le damos a tus datos.<br /><a className="legal-doc fc-orange" href="#">VER DOCUMENTO</a></p>
							
						</section>
						<section className="f-left half pre-icon">
							<div className="icon medium copyright f-left"></div>
							<header><h3 className="legal-doc" href="#">COPYRIGHT</h3></header>
							<p>Todo lo relativo a la titularidad de las <strong>imágenes, textos…</strong> que aparecen en la web de Piensa Solutions.
								<br />
								<a className="legal-doc fc-orange" href="#">VER DOCUMENTO</a>
							</p>
						</section>
						<div className="clear-both"></div>
					</div>
					<div className="content">
						<section className="f-left half pre-icon">
							<div className="icon medium c-list f-left"></div>
							<header><h3 className="legal-doc" href="#">CONDICIONES DE USO</h3></header>	
							<p>Sobre la <strong>navegación en nuestra web</strong> y otros temas de interés para nuestros <strong>clientes</strong>.
								<br />
								<a className="legal-doc fc-orange" href="#">VER DOCUMENTO</a>
							</p>
						</section>
						<section className="f-left half pre-icon">
							<div className="icon medium cookie f-left"></div>
							<header><h3 className="legal-doc" href="#">COOKIES</h3></header>
							<p>Conoce nuestra <strong>Política de recogida y tratamiento de cookies</strong> según la legislación vigente.
								<br />
								<a className="legal-doc fc-orange" href="#">VER DOCUMENTO</a>
							</p>
						</section>
						<div className="clear-both"></div>
					</div>
				</article>
			</div>

			<div className="row bc-white">
				<article className="block w12 document-list">
					<header>
						<div className="icon o-document big f-left">
						</div>
						<h2 className="light">Contratos y Condiciones promocionales</h2>
						<p>A continuación te mostramos todos los <strong>contratos, condiciones promocionales y políticas de nombres de dominio en vigor</strong>, aplicables a nuestros servicios.</p>
					</header>
					<div className="content">
						<div className="internal-border">
							<div className="content">
								<section>
									<div className="icon small briefcase f-left"></div>
									<header><h3>CONTRATOS</h3></header>	
									<div className="f-left half">
										<ul>
											<li><a href="#" className="legal-doc">Contrato general de nombres de Dominio</a></li>
											<li><a href="#" className="legal-doc">Contrato de Hosting</a></li>
											<li><a href="#" className="legal-doc">Contrato de "Mi Página Web"</a></li>
											<li><a href="#" className="legal-doc">Contrato de Protección de Dominio</a></li>
										</ul>
									</div>
									<div className="f-left half">
										<ul>
											<li><a href="#" className="legal-doc">Contrato de Tienda Online</a></li>
											<li><a href="#" className="legal-doc">Contrato de Certificados de Seguridad (SSL)</a></li>
											<li><a href="#" className="legal-doc">Contrato de rankingCoach</a></li>
										</ul>
									</div>
									<div className="clear-both"></div>
								</section>
								<section>
									<div className="icon small briefcase f-left"></div>
									<header><h3>CONDICIONES PROMOCIONALES</h3></header>	
									<div className="f-left half">
										<ul>
											<li><a href="#" className="legal-doc">Condiciones de la promoción "3 meses gratis en Personal Hosting"</a></li>
											<li><a href="#" className="legal-doc">Condiciones de la promoción "50% de descuento en mi página web y en tiendas online"</a></li>
											<li><a href="#" className="legal-doc">Condiciones de la promoción "30 días gratis rankingCoach"</a></li>									
										</ul>
									</div>
									<div className="f-left half">
										<ul>
											<li><a href="#" className="legal-doc">Condiciones de uso de "Google Ads"</a></li>
											<li><a href="#" className="legal-doc">Condiciones de la promoción "Precio especial en Proffesional Hosting o Business Hosting"</a></li>
											<li><a href="#" className="legal-doc">Condiciones de la promoción "Dominio gratis contratando un Proffesional Hosting o Business Hosting"</a></li>
										</ul>
									</div>
									<div className="clear-both"></div>
								</section>
								<section>
									<div className="icon small briefcase f-left"></div>
									<header><h3>OTROS DOCUMENTOS</h3></header>	
									<div className="f-left half">
										<ul>
											<li><a href="#" className="legal-doc">Responsabilidades del titular de un nombre de dominio genérico</a></li>
											<li><a href="#" className="legal-doc">Responsabilidades del titular de un nombre de dominio .eu</a></li>

											<li><a href="#" className="legal-doc">Responsabilidades del titular de un nombre de dominio .cat</a></li>
											<li>
												<a href="#" className="legal-doc">Normas de asignación de un nombre de dominio bajo .es</a>
											</li>

											<li><a href="#" className="legal-doc">Política uniforme de solución de conflictos de nombres de dominio genéricos</a></li>
											<li><a href="#" className="legal-doc">Reglamento de la Política uniforme de solución de controversias de nombres de dominio genéricos</a></li>
											<li><a href="#" className="legal-doc">Política de renovación y gestión de nombres de dominios genéricos</a></li>
										</ul>
									</div>
									<div className="f-left half">
										<ul>
											<li><a href="#" className="legal-doc">Reglamento del Procedimiento de Resolución extrajudicial de conflictos en materia de nombres de dominio .es</a></li>
											<li><a href="#" className="legal-doc">Reglamento de Resolución de Controversias en materia de dominios .eu</a></li>
											<li><a href="#" className="legal-doc">Reglamento adicional del RAC de la corte de arbitraje en materia de dominios .eu</a></li>
											<li><a href="#" className="legal-doc">Responsabilidades del titular de un nombre de dominio .mx</a></li>
											<li><a href="#" className="legal-doc">Reglamento de solución de controversias en materia de nombres de dominio .mx</a></li>
										</ul>
									</div>
									<div className="clear-both"></div>
								</section>
							</div>
						</div>
					</div>
				</article>
			</div>
		</main>*/}
		{/*<footer>
	<div className="top">
		<div className="block w12">
			<div className="block w3">
				<ul id="other_links">
					<li id="company">
						<a href="#" title="Quiénes somos">
							<div className="ficon" id="fi_company"></div>QUIÉNES SOMOS
						</a>
					</li>
					<li id="contact">
						<a href="#" title="Contacta con nosotros">
							<div className="ficon" id="fi_contact"></div>CONTACTA CON NOSOTROS
						</a>
					</li>
					<li id="legal">
						<a href="#" title="Avisos legales">
							<div className="ficon" id="fi_legal"></div>AVISOS LEGALES
						</a>
					</li>
					<li id="sitemap">
						<a href="#" title="Mapa web">
							<div className="ficon" id="fi_sitemap"></div>MAPA WEB
						</a>
					</li>
					<li id="help">
						<a href="#" title="Ayuda">
							<div className="ficon" id="fi_help"></div>¿NECESITAS AYUDA?
						</a>
					</li>
					<li id="whois">
						<a href="#" title="Whois">
							<div className="ficon" id="fi_whois"></div>WHOIS
						</a>
					</li>
					<li id="blog">
						<a href="#" title="Blog de Piensa Solutions" target="_blank">
							<div className="ficon" id="fi_blog"></div>Blog
						</a>
					</li>
				</ul>
			</div>
			<div className="block w3">
				<h4>Redes Sociales</h4>
				<ul id="other_links">
					<li>
						<a href="#" className="lower" target="_blank" title="Síguenos en Facebook">
							<div className="ficon" id="fi_facebook"></div>
							Facebook
						</a>
					</li>	
					<li>
						<a href="#" className="lower" target="_blank" title="Síguenos en Twitter">
							<div className="ficon" id="fi_twitter"></div>
							Twitter
						</a>
					</li>
					<li>
						<a href="#" className="lower" target="_blank" title="Síguenos en Google+"> 
							<div className="ficon" id="fi_google-plus"></div>
							Google+
						</a>&gt;
					</li>
				</ul>
			</div>
			<div className="block w3" id="postblog">
				<h4>Últimas entradas blog</h4>
			</div>
		</div>
	</div>
	<div className="bottom">
		<div className="center">
			<a href="#"><div id="foot_logo"></div></a>
			<label id="legal"><span>© Copyright Tesys Internet S.L.U.</span><span>Precios sin IVA.</span></label>
			<a rel="nofollow" target="_blank" title="United Internet" href="#">
				<div id="united"></div>
			</a>
		</div>
	</div>
		</footer>*/}
<div className="lightbox-back" style={{opacity: 1,display: "block"}}><div className="lightbox-window block" style={{opacity: 1,display: "block"}}><div className="lightbox-close"></div>
{/*<header className="bc-orange"><h2 className="bc-darkgrey fc-white uppercase" dangerouslySetInnerHTML={{__html: content.titulo}}></h2></header>*/}<div className="lightbox-content legal-lbox"><h1 dangerouslySetInnerHTML={{__html: content.titulo}}></h1>
{content.referencia != '' ? <p className="ref_contrato" dangerouslySetInnerHTML={{__html: 'Ref.: '+content.referencia}}></p> : null}
<p>&nbsp;<br /></p>
<div dangerouslySetInnerHTML={{__html: content.cabeceraNotVar.replace(/<a /g, '<a target="_blank" ')}}></div>
<div dangerouslySetInnerHTML={{__html: content.textNotVar.replace(/<a /g, '<a target="_blank" ')}}></div>
</div></div></div>

</>
    );

}

export default PreviewContratPiensa;