import React, { Component } from 'react';
import './styles.css';

class HistoricsDropdown extends Component {
	constructor(props){
	  super(props)
	  this.state = {
	    listOpen: false,
	    selected: this.props.selected
	  }
	}

	toggleList = () => {
	  this.setState(prevState => ({
	    listOpen: !prevState.listOpen
	  }))
	}

	changeHistoric = (id) => {
		this.props.changeHistoric(id);
		this.setState({selected:id,listOpen:!this.state.listOpen});
	}
	
	render() {
	  return(
	    <div className="dd-wrapper">
		    <div className="editcontract-content-buttons-historic" onClick={() => this.toggleList()} title={this.props.title}>
				<svg viewBox="0 0 24 24">
    				<path d="M13.5,8H12V13L16.28,15.54L17,14.33L13.5,12.25V8M13,3A9,9 0 0,0 4,12H1L4.96,16.03L9,12H6A7,7 0 0,1 13,5A7,7 0 0,1 20,12A7,7 0 0,1 13,19C11.07,19 9.32,18.21 8.06,16.94L6.64,18.36C8.27,20 10.5,21 13,21A9,9 0 0,0 22,12A9,9 0 0,0 13,3" />
				</svg>
		        <span className="dd-header-title">{this.props.title}</span>
		        {this.state.listOpen
		          ? <span>&#11205;</span>
		          : <span>&#11206;</span>
		        }
		    </div>
	    {this.state.listOpen ? (
	     	<ul className="dd-list">
		       {this.props.list.map((item) => (
		       		this.state.selected === item.id ? (
		         		<li className="dd-list-item" key={item.id} onClick={() => this.setState({listOpen:!this.state.listOpen})}><span>&#10004;</span> {item.lastupdated} - {item.user}</li>
		         	) : (
		         		<li className="dd-list-item" key={item.id} onClick={() => this.changeHistoric(item.id)}><span className="nochecked"></span> {item.lastupdated} - {item.user}</li>
		         	)
		        ))}
			</ul>
		) : null}
	    </div>
	  )
	}
}

export default HistoricsDropdown;