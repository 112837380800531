import React from 'react';

const PreviewContratArsys = ({ content }) => {
    return (
    	<>
        	<link rel="stylesheet" type="text/css" href="https://www.arsys.es/get/css/terms.css" media="screen" />
			<header className="previewshow">
				<div className="width">
					<img src="https://www.arsys.es/img/shared/logo.png?=CV-1047" />
					<hr />
					<a className="posttitle" title="Legal">Legal</a>
                    {content.referencia != '' ? <p className="ref_contrato" dangerouslySetInnerHTML={{__html: 'Ref.: '+content.referencia}}></p> : null}
				</div>
			</header>
			<main className="previewshow">
				<div className="width">
					<h1 dangerouslySetInnerHTML={{__html: content.titulo}}></h1>
					<div>
                        <div dangerouslySetInnerHTML={{__html: content.cabeceraNotVar.replace(/<a /g, '<a target="_blank" ')}}></div>
                        <div dangerouslySetInnerHTML={{__html: content.textNotVar.replace(/<a /g, '<a target="_blank" ')}}></div>
					</div>
				</div>
			</main>
			<footer className="previewshow">
				<div className="width">
					<img src="https://www.arsys.es/img/shared/logo.png?=CV-1047" />
					<p className="copyright">Copyright © Arsys</p>
				</div>
			</footer>
        </>
    );
}

export default PreviewContratArsys;