import React, { Component } from 'react';
import Loading from '../../Loading';
import { Editor } from '@tinymce/tinymce-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './styles.css';
import {getEditorTinyApiKey} from '../../Lib/functions';

class ClonePressNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docOutput: this.props.pressnote,
            showLoading: false,
            selectedPdf: null,
            inputFileKey:Date.now()
        }
    }

    handleChangeEditorPressNote = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.content = event.target.getContent();
      this.setState({docOutput:docOutput});
    }

    handleChangeTitle = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.titulo = event.target.value;
      this.setState({docOutput:docOutput});
    }

    handleChangeDescription = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.description = event.target.value;
      this.setState({docOutput:docOutput});
    }

    handleChangeKeywords = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.keywords = event.target.value;
      this.setState({docOutput:docOutput});
    }

    handleChangeUrl = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.url = event.target.value;
      this.setState({docOutput:docOutput});
    }

    handleChangePdf = (event) => {
      let docOutput = this.state.docOutput;
      if (event.target.files[0]) {
        docOutput.pdf = event.target.files[0].name;
      } else {
        docOutput.pdf = null;
      }
      this.setState({docOutput:docOutput,inputFileKey:Date.now(),selectedPdf:event.target.files[0]});
    }

    handleChangeDate = (event) => {
        let docOutput = this.state.docOutput;
        docOutput.date = event;
        this.setState({docOutput:docOutput});
    }

    deletePdf = () => {
      let docOutput = this.state.docOutput;
      docOutput.pdf = null;
      this.setState({docOutput:docOutput,inputFileKey:Date.now(),selectedPdf:null});
    }
  
    render() {
        return (
            <div className="editcontract-background">
                <div className="editcontract-background-in">
                  <div className="editcontract-background-in-header">Clonar Nota de Prensa</div>
                    <div className="editcontract-close" onClick={() => this.props.closeClonePressNote()}></div>
                    <div className="editcontract-content">
                        <div className="editcontract-content-buttons">
                          <div className="editcontract-content-buttons-save" onClick={() => this.props.savePressNote(this.state.docOutput, this.state.selectedPdf)} title="Guardar Nota de Prensa">
                            <svg viewBox="0 0 24 24">
                              <path d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" />
                            </svg>
                            <span>Guardar Nota de Prensa</span>
                          </div>
                          <div className="editcontract-content-buttons-preview" onClick={() => this.props.showPreview(this.state.docOutput)} title="Vista previa">
                            <svg viewBox="0 0 24 24">
                              <path d="M9,13A3,3 0 0,0 12,16A3,3 0 0,0 15,13A3,3 0 0,0 12,10A3,3 0 0,0 9,13M20,19.59V8L14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18C18.45,22 18.85,21.85 19.19,21.6L14.76,17.17C13.96,17.69 13,18 12,18A5,5 0 0,1 7,13A5,5 0 0,1 12,8A5,5 0 0,1 17,13C17,14 16.69,14.96 16.17,15.75L20,19.59Z" />
                            </svg>
                            <span>Vista previa</span>
                          </div>
                        </div>
                        <div className="editcontract-content-docoutput">
                            <div className="editcontract-content-title">
                                <div className="editcontract-content-title-text">Título Nota de Prensa:</div>
                                <div className="editcontract-content-title-value"><input type="text" value={this.state.docOutput.titulo} onChange={this.handleChangeTitle} placeholder="Título Nota de Prensa" /></div>
                            </div>
                            <div className="addcontract-content-title">
                              <div className="addcontract-content-title-text">Meta Description:</div>
                              <div className="addcontract-content-title-value"><input type="text" value={this.state.docOutput.description} onChange={this.handleChangeDescription} placeholder="Meta Description" /></div>
                            </div>
                            <div className="addcontract-content-title">
                              <div className="addcontract-content-title-text">Meta Keywords:</div>
                              <div className="addcontract-content-title-value"><input type="text" value={this.state.docOutput.keywords} onChange={this.handleChangeKeywords} placeholder="Meta Keywords" /></div>
                            </div>
                            <div className="addcontract-content-title">
                                <div className="addcontract-content-title-text">Fecha Nota de Prensa:</div>
                                <div className="addcontract-content-title-value"><DatePicker dateFormat="yyyy/MM/dd" placeholderText="Fecha Nota de Prensa" selected={this.state.docOutput.date} onChange={this.handleChangeDate} /></div>
                            </div>
                            <div className="editcontract-content-title">
                                <div className="editcontract-content-title-text">Url Nota de Prensa:</div> 
                                <div className="addcontract-content-title-value">
                                    <label htmlFor="url">https://www.arsys.es/empresa/prensa/</label>
                                    <input type="text" id="url" value={this.state.docOutput.url} onChange={this.handleChangeUrl} placeholder="Url Nota de Prensa" />
                                </div>
                                <input name="image" type="file" id="upload" className="hidden"></input>
                            </div>
                            <div className="editcontract-content-title">
                              <div className="editcontract-content-title-text">PDF Nota de Prensa:</div> 
                              <div className="editcontract-content-title-value">
                                <input name="pdffile" type="file" key={this.state.inputFileKey} onChange={this.handleChangePdf} className={this.state.docOutput.pdf ? "hidden" : "" }></input>
                                {this.state.docOutput.pdf ? (
                                  <div>
                                    {this.state.docOutput.pdf} 
                                    <div className="delete-pdf" onClick={() => this.deletePdf()}>
                                      <svg viewBox="0 0 24 24">
                                        <path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                                      </svg>
                                      <span>Eliminar PDF</span>
                                    </div>
                                  </div>
                                ) : (
                                  null
                                )}
                              </div>
                            </div>
                <div className="editcontract-content-contract">
                  <div className="editcontract-content-contract-title">
                    Contrato:
                  </div>
                  <Editor apiKey={getEditorTinyApiKey()}
                          key="contratarsys"
                          initialValue={this.state.docOutput.content}
                          init={{
                            content_style: "html {min-width: 100%;scrollbar-color: #bbb #ddd;scrollbar-width: thin;} " + 
                                              "body {text-align: left;padding: 15px;}" +
                                              "* {font-size: 14px;}" +
                                              "p {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;margin-bottom:8px;margin-left:0px;margin-right:30px;margin-top:8px;padding:0;text-align:left;} " + 
                                              "h1 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:32px;font-weight:500;letter-spacing:0.3px;line-height:40px;margin-bottom:24px;margin-left:0px;margin-right:0px;margin-top:48px;padding-bottom:8px;padding:8px 0px;text-align:left;text-transform:uppercase;}" +
                                              "h2 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:24px;font-weight:500;letter-spacing:0.3px;line-height:40px;margin-bottom:0px;margin-left:0px;margin-right:0px;margin-top:0px;padding:0;text-align:left;text-transform:uppercase;} " +
                                              "h3 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:20px;font-weight:500;letter-spacing:-1px;line-height:24px;margin-bottom:0px;margin-left:0px;margin-right:0px;margin-top:16px;padding:0;text-align:left;text-transform:uppercase;}" +
                                              "h4 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:20px;font-weight:500;letter-spacing:-1px;line-height:24px;margin-bottom:16px;margin-left:0px;margin-right:0px;margin-top:16px;padding:0px;text-align:left;text-transform:capitalize;}" +
                                              "h5, h6 {color:rgb(50, 50, 50);display:block;font-family:os, sans-serif;font-size:16px;font-weight:700;line-height:16px;margin:0px;padding:0px;text-align:left;text-transform:none;}" + 
                                              "ul {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-type:circle;margin:16px 24px;padding:0;text-align:left;}" +
                                              "ol {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-type:decimal;margin-bottom:16px;margin-left:24px;margin-right:24px;margin-top:16px;padding:0px;text-align:left;}" +
                                              "li {color:rgb(55, 55, 55);display:list-item;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-image:none;list-style-position:outside;margin-bottom:0px;margin-left:35px;margin-right:0px;margin-top:0px;padding:0;text-align:left;}" +
                                              "table, tr, td {border: 1px solid #000;}" + 
                                              "table tr td {padding: 10px 15px;}" +
                                              "br {display: block;}" +
                                              "::-webkit-scrollbar {width: 8px;height:8px;}" +
                                              "::-webkit-scrollbar-thumb {background: #bbb;border-radius: 10px;}" +
                                              "::-webkit-scrollbar-track {background: #ddd;}",
                            content_css : "https://www.arsys.es/resource/style/a.css,https://www.arsys.es/resource/style/legal.css,https://www.arsys.es/resource/style/empresa.css",
                            plugins: 'link image code paste lists searchreplace table',
                            menu: {},
                            toolbar: 'undo redo | formatselect | bold italic underline strikethrough | alignleft | aligncenter | alignright | bullist numlist | image | link | table | searchreplace | code',
                            table_default_attributes: {'border': '1'},
                            table_default_styles: {'border-collapse': 'collapse','width': '98%','border':'1px solid #000','border-spacing': '0'},
                            table_appearance_options: false,
                            table_responsive_width: true,
                            file_picker_callback: function(callback, value, meta) {
                              if (meta.filetype == 'image') {
                                document.getElementById('upload').click();
                                document.getElementById('upload').addEventListener('change', function() {
                                  var file = this.files[0];
                                  var reader = new FileReader();
                                  reader.onload = function(e) {
                                    callback(e.target.result, {
                                      alt: ''
                                    });
                                  };
                                  reader.readAsDataURL(file);
                                });
                              }
                            },
                            height: '500px',
                            paste_retain_style_properties: "",
                            paste_preprocess: function(plugin, args) {
                              args.content = args.content.replace(/http\:\/\/www\.arsys/g, 'https://www.arsys');
                              args.content = args.content.replace(/http\:\/\/www\.piensasolutions/g, 'https://www.piensasolutions');
                              args.content = args.content.replace(/ www\.arsys/g, ' https://www.arsys');
                              args.content = args.content.replace(/ www\.piensasolutions/g, ' https://www.piensasolutions');
                              args.content = args.content.replace(/>www\.arsys/g, '>https://www.arsys');
                              args.content = args.content.replace(/>www\.piensasolutions/g, '>https://www.piensasolutions');
                              args.content = args.content.replace(/<div>/g,'<p>');
                              args.content = args.content.replace(/<\/div>/g,'</p>');
                              var urlRegex = /(\b (https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                              args.content = args.content.replace(urlRegex, function(url) {
                                  return ' <a href="' + url.trim() + '">' + url.trim() + '</a>';
                              })
                            },
                            branding: false
                          }}
                          onChange={this.handleChangeEditorPressNote}
                        />
                </div>
              </div>
          </div>
          {this.state.showLoading ? <Loading /> : null}
        </div>
      </div>
        );
    }
}

export default ClonePressNote;