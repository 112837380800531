import React from 'react';
import './styles.css';

const Alerts = ({ message, closeAlert }) => {
    return (
      <div className="alert">
        <div className="alert-in">
          <div className="alert-in-title">{message}</div>
          <div className="alert-in-buttons">
            <div className="alert-in-button-yes" onClick={() => closeAlert()}>OK</div>
          </div>
        </div>
      </div>
    );

}

export default Alerts;
