import React from 'react';
import './styles.css';

const ListJobOffers = ({ index, joboffer, edita_cont, borrar_cont, clonar_cont, publish_cont, unpublish_cont }) => {
    return (
      <div className={index % 2 === 0 ? "resultsrow pair" : "resultsrow"}>
        <div className="resultsrow-in">
          <div className="resultsrow-column pressnotes">
            <div className="resultsrow-title">{joboffer.lastUpdate}</div>
          </div>
          <div className="resultsrow-column pressnotes">
            <div className="resultsrow-title" dangerouslySetInnerHTML = { { __html: joboffer.titulo } }></div>
          </div>
          <div className="resultsrow-column pressnotes">
            <div className="resultsrow-title">{joboffer.pdf}</div>
          </div>
          <div className="resultsrow-column pressnotes">
            <div className="resultsrow-title">{joboffer.date}</div>
          </div>
          <div className="resultsrow-column pressnotes">
            <div className="resultsrow-title">{joboffer.visible === 0 ? <span className="not-published" onClick={() => publish_cont(joboffer.action)}></span>:<span className="yes-published" onClick={() => unpublish_cont(joboffer.action)}></span>}</div>
          </div>
          <div className="resultsrow-column pressnotes">
            <div className="resultsrow-title">{joboffer.url}</div>
          </div>
          <div className="resultsrow-column pressnotes">
            <div className="resultsrow-title link edit" onClick={() => edita_cont(joboffer.action)}>
              <svg viewBox="0 0 24 24">
                <path d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
              </svg>
              <span>Editar</span>
            </div>
            <div className="resultsrow-title link clone" onClick={() => clonar_cont(joboffer.action)}>
              <svg viewBox="0 0 24 24">
                <path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
              </svg>
              <span>Clonar</span>
            </div>
            <div className="resultsrow-title link delete" onClick={() => borrar_cont(joboffer)}>
              <svg viewBox="0 0 24 24">
                <path d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
              </svg> 
              <span>Borrar</span>
            </div>
          </div>
        </div>
      </div>
    );

}

export default ListJobOffers;
