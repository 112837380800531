import React, { Component } from 'react';
import Loading from '../Loading';
/*import customData from './backContratosPr.json';
import customDataContract from './contupdate.json'*/
import ListContrats from './ListContrats';
import ListContratsHeader from './ListContratsHeader';
import AddContract from './AddContract';
import PreviewContrat from './PreviewContrat';
import EditContract from './EditContract';
import DeleteContract from './DeleteContract';
import CloneContract from './CloneContract';
import Login from '../Login';
import FileSaver from 'file-saver';
import Alerts from '../Alerts';
import {authParams} from '../Lib/functions';

//const serverRoute = 'https://node.devos.io:3001';
//const serverRoute = 'http://localhost:3001';

class App extends Component {
  constructor(props) {
    super(props);
    let username = this.props.username;
    if (sessionStorage.getItem('username')) {
      username = sessionStorage.getItem('username');
    }
    this.state = {
      contrats:[],
      loading: false,
      orderField: 'lastUpdate',
      orderDir: 'desc',
      showAddContract: false,
      showEditContract: false,
      showDeleteContract: false,
      showCloneContract: false,
      contractSelected: null,
      contractToDeleted: null,
      preview: null,
      categories:[],
      unities:[],
      search: '',
      showAlerts: false,
      alertMessage: true,
      username:username,
    }
  }

  componentDidMount = () => {
    if (this.props.username || sessionStorage.getItem('username')) {
      this.setState({loading:true});
      this.getContrats();
      this.getCategories();
      this.getUnities();
    }
  }

  getContrats = () => {
    this.setState({loading : true});
    this.getContratsCall()
      .then(res => {
        const contrats = [];
        console.log(res);
        res.data.forEach(item => {
          contrats.push(item);
        })
        this.setState({contrats:contrats,loading : false});
      })
      .catch(error => {
        alert(error);
        this.setState({contrats:[],loading : false});
      });
  }

  getContratsCall = (obj = {}) => {
    return new Promise((resolve, reject) => {
      
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/contrats?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/contrats';
      fetch(urlServer)
        .then(res => res.json())
        .then(json => {
          if (json.Response === 'True') {
            resolve(json);
          } else {
            reject(json.Error);
          }
        })
        .catch(err => {
          console.log(err);
          reject('api error');
        });
        
        //resolve(customData);
    });
  }

  getCategories = () => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/categories?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/categories';
      fetch(urlServer)
        .then(res => res.json())
        .then(json => {
          if (json.Response === 'True') {
            const categories = [];
            json.data.forEach(item => {
              categories.push(item);
            })
            this.setState({categories:categories});
          } else {
            console.log(json.Error);
          }
        })
        .catch(err => {
          console.log(err);
        });
  }

  getUnities = () => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/unities?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/unities';
      fetch(urlServer)
        .then(res => res.json())
        .then(json => {
          if (json.Response === 'True') {
            const unities = [];
            json.data.forEach(item => {
              unities.push(item);
            })
            this.setState({unities:unities});
          } else {
            console.log(json.Error);
          }
        })
        .catch(err => {
          console.log(err);
        });
  }

  changeOrder = (order, previousOrder) => {
    console.log(order+' - '+previousOrder);
    let orderDir = this.state.orderDir;
    if (order === previousOrder) {
      if (orderDir === "asc") {
        orderDir = "desc";
      } else {
        orderDir = "asc";
      }
    } else {
        orderDir = "asc";
    }
    this.setState({orderDir:orderDir,orderField:order});
  }

  showAddContract = () => {
    this.setState({showAddContract:true});
  }

  showPreview = (content) => {
    console.log(content);
    this.setState({preview:content});
  }

  saveContract = (content) => {
    /*console.log('add new contract');
    let htmlContent = '<h1>' + content.titulo + '</h1><p className="ref_contrato">' + content.referencia + '</p>' + '<div>' + content.cabeceraNotVar + '</div><div>' + content.textNotVar + '</div>';
    let file = new File([htmlContent], "prueba.html", {type: "text/plain;charset=utf-8"});
    FileSaver.saveAs(file);
    let htmContent = '<h1>' + content.titulo + '</h1><p className="ref_contrato">' + content.referencia + '</p>' + '<div>' + content.cabeceraVar + '</div><div>' + content.textNotVar + '</div>';
    */
    //let htmHeader = '<?xml version="1.0" encoding="iso-8859-1"?><!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd"><html xmlns="http://www.w3.org/1999/xhtml"><head><title>Contrato - arsys.es</title><meta http-equiv="Content-Type" content="text/html; charset=iso-8859-1"/><style type="text/css">/*<![CDATA[*/*{font-size:13px; font-family: Arial,Helvetica,sans-serif;}h1{font-size: 14px; font-family: "Trebuchet MS",Verdana,Arial,Helvetica,sans-serif; text-transform: uppercase; margin-top: 0; margin-bottom: 10px; padding-top: 0pt; padding-bottom: 3px; padding-left-value: 5px; border-bottom:1px solid #aaa;}p{margin-bottom: 1em;}.ref_contrato{text-align: right; margin-top: 5px; margin-right: 10px; margin-bottom: 5px; font-weight: bold;}h2{padding-top: 0; padding-bottom: 3px; margin-top: 20px; margin-bottom: 10px; border-bottom:1px solid #ccc; font-size: 13px; font-family: "Trebuchet MS",Verdana,Arial,Helvetica,sans-serif; text-transform: uppercase;}h3{padding-top: 0; padding-bottom: 3px; margin-top: 15px; margin-bottom: 10px; font-size: 13px; font-family: "Trebuchet MS",Verdana,Arial,Helvetica,sans-serif; text-transform: uppercase;}strong{rgb(0, 0, 0);}.b{color:black; font-weight:bold;}a{color: rgb(1, 130, 195);}p.pie{margin-top:50px; font-size: 11px;}table{border: 1px solid #eee;}th{padding: 2ex 3ex; background-color: #d5e8f9; color: #406c8f;}td{padding: 1ex; border-bottom: 1px solid #efefef;}.importante{margin:20px 30px; padding: 10px 20px; font-size: 90%; border:1px solid #D7DDE3; background: #F4F6F8;}/*]]>*/</style></head><body>';
    //let htmFooter = '</body></html>';
    //let file2 = new File([htmHeader+htmContent+htmFooter], "prueba.htm", {type: "text/plain;charset=utf-8"});
    //FileSaver.saveAs(file2);

    if (content.url.trim() === '') {
      this.setState({showAlerts:true,alertMessage:'Añade el nombre del archivo.'});
    } else if (content.url.indexOf(' ') > -1) {
      this.setState({showAlerts:true,alertMessage:'El nombre del archivo no debe tener caracteres en blanco'});
    } else if (!(/^([a-zA-Z0-9 _-]+)$/.test(content.url))) {
      this.setState({showAlerts:true,alertMessage:'El nombre del archivo tiene caracteres no adecuados. Utiliza a-z A-Z 0-9 _ -'});
    } else if (this.state.contrats.filter((item,index) => {
              return (item.url.toLowerCase().trim() === content.url.toLowerCase().trim() && item.action !== content.id && parseInt(item.unidad_id) === parseInt(content.unidad));
            }).length > 0) {
      this.setState({showAlerts:true,alertMessage:'Ese nombre del archivo ya está ocupado. Prueba con otro.'});
    } else {

      this.setState({loading : true});
      let tempTextNotVar= content.textNotVar;
      tempTextNotVar = tempTextNotVar.replace(/\&ldquo;|\&rdquo;/g,'"');
      tempTextNotVar = tempTextNotVar.replace(/\&rsquo;/g,'\'');
      content.textNotVar = tempTextNotVar.replace(/\<br \/\>\<br \/\>/g,'<br>&nbsp;<br>');
      let tempCabeceraNotVar= content.cabeceraNotVar;
      tempCabeceraNotVar = tempCabeceraNotVar.replace(/\&ldquo;|\&rdquo;/g,'"');
      tempCabeceraNotVar = tempCabeceraNotVar.replace(/\&rsquo;/g,'\'');
      content.cabeceraNotVar = tempCabeceraNotVar.replace(/\<br \/\>\<br \/\>/g,'<br>&nbsp;<br>');
      let tempCabeceraVar= content.cabeceraVar;
      tempCabeceraVar = tempCabeceraVar.replace(/\&ldquo;|\&rdquo;/g,'"');
      tempCabeceraVar = tempCabeceraVar.replace(/\&rsquo;/g,'\'');
      content.cabeceraVar = tempCabeceraVar.replace(/\<br \/\>\<br \/\>/g,'<br>&nbsp;<br>');

      this.insertNewContract(content)
        .then(res => {
          console.log(res);
          let contrats = this.state.contrats;
          if (content.id > 0) {
            contrats.map((item,index) => {
              if (item.action === content.id) {
                this.state.categories.map((category,index) => {
                  if (parseInt(category.id) === parseInt(content.categoria)) {
                    item.categoria = category.title;
                  }
                });
                item.categoria_id = content.categoria;
                const datenow = new Date();
                item.lastUpdate = datenow.getFullYear() + "-" + ("0"+(datenow.getMonth()+1)).slice(-2) + "-" + ("0" + datenow.getDate()).slice(-2) + " " + ("0" + datenow.getHours()).slice(-2) + ":" + ("0" + datenow.getMinutes()).slice(-2) + ":" + ("0" + datenow.getSeconds()).slice(-2);
                item.referencia = content.referencia;
                item.titulo = content.titulo;
                this.state.unities.map((unity,index) => {
                  if (parseInt(unity.id) === parseInt(content.unidad)) {
                    item.unidad = unity.title;
                  }
                });
                item.unidad_id = content.unidad;
                item.url = content.url;
                item.visible = 0;
              };
            });
          } else {
            console.log("es un add: "+content.id);
                let item = {};
                item.action = res;
                this.state.categories.map((category,index) => {
                  if (parseInt(category.id) === parseInt(content.categoria)) {
                    item.categoria = category.title;
                  }
                });
                item.categoria_id = content.categoria;
                const datenow = new Date();
                item.lastUpdate = datenow.getFullYear() + "-" + ("0"+(datenow.getMonth()+1)).slice(-2) + "-" + ("0" + datenow.getDate()).slice(-2) + " " + ("0" + datenow.getHours()).slice(-2) + ":" + ("0" + datenow.getMinutes()).slice(-2) + ":" + ("0" + datenow.getSeconds()).slice(-2);
                item.referencia = content.referencia;
                item.titulo = content.titulo;
                this.state.unities.map((unity,index) => {
                  if (parseInt(unity.id) === parseInt(content.unidad)) {
                    item.unidad = unity.title;
                  }
                });
                item.unidad_id = content.unidad;
                item.url = content.url;
                item.visible = 0;
                contrats.push(item);
          }
          this.setState({contrats:contrats,showEditContract:false,showAddContract:false,showCloneContract:false,loading : false});
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  escapeHTML = (str) => {
      var div = document.createElement('div');
      var text = document.createTextNode(str);
      div.appendChild(text);
      return div.innerHTML;
  }

  showEditContrat = (value) => {
    /*let pos = value.substring(value.indexOf('changeStatusCont'));
    pos = pos.substring(0,pos.indexOf('"'));
    pos = pos.replace('changeStatusCont(\'','');
    pos = pos.replace('\')','');
    console.log(pos);*/
  
    this.setState({loading : true});
    this.getContratData(value)
      .then(res => {
        this.setState({contractSelected:res[0],showEditContract:true,loading : false});
      })
      .catch(error => {
        console.log(error);
      });
  }

  getContratData = (opts) => {
    console.log(opts);
    return new Promise((resolve, reject) => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/contrats?t='+ timestamp + '&k=' + authParam + '&id=' + opts;
      //let urlServer = this.props.serverRoute + '/contrats?id='+opts;
      fetch(urlServer)
        .then(res => res.json())
        .then(json => {
          if (json.Response === 'True') {
            resolve(json.data);
          } else {
            reject(json.Error);
          }
        })
        .catch(err => {
          console.log(err);
          reject('api error');
        });

        //resolve(customDataContract);
    });
  }

  insertNewContract = (content) => {
    console.log(content);
    content.username = this.state.username;
    return new Promise((resolve, reject) => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/contrats?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/contrats';
      fetch(urlServer, {
        method: 'POST',
        body: JSON.stringify(content),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .then(json => {
          console.log(json);
          if (json.Response === 'True') {
            console.log(json.newid);
            resolve(json.newid);
          } else {
            reject(json.Error);
          }
        })
        .catch(err => {
          console.log(err);
          reject('api error');
        });
    });
  }

  showDeleteContract = (value) => {
    this.setState({contractToDeleted:value,showDeleteContract:true});
  }

  showCloneContract = (value) => {
    console.log(value);
    this.setState({loading : true});
    this.getContratData(value)
      .then(res => {
        res[0].id = 0;
        res[0].referencia = '';
        res[0].titulo = '';
        res[0].url ='';
        this.setState({contractSelected:res[0],showCloneContract:true,loading : false});
      })
      .catch(error => {
        console.log(error);
      });
  }

  deleteContract = (value) => {
    console.log(value);

    return new Promise((resolve, reject) => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/contrats?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/contrats';
      fetch(urlServer, {
        method: 'DELETE',
        body: JSON.stringify(value),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .then(json => {
          console.log(json);
          if (json.Response === 'True') {
            let contrats = this.state.contrats;
            let filtered = contrats.filter((item,index) => {
              return item.action !== value.action;
            });
            console.log(filtered);
            resolve();
            this.setState({contrats:filtered,showDeleteContract:false,contractToDeleted:null});
          } else {
            reject(json.Error);
            this.setState({showDeleteContract:false,contractToDeleted:null});
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({showDeleteContract:false,contractToDeleted:null});
          reject('api error');
        });
    });
  }

  handleSearch = (event) => {
    this.setState({search:event.target.value});
  }

  publishContrat = (value) => {
    console.log("publish: " + value);
    this.setState({loading:true});

    return new Promise((resolve, reject) => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/publish?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/publish';
      fetch(urlServer, {
        method: 'POST',
        body: JSON.stringify({id:value}),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .then(json => {
          console.log(json);
          if (json.Response === 'True') {
            let contrats = this.state.contrats;
            contrats.map((item,index) => {
              if (item.action === value) {
                item.visible = 1;
              }
            });
            resolve();
            this.setState({contrats:contrats,loading:false});
          } else {
            reject(json.Error);
            this.setState({loading:false});
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({loading:false});
          reject('api error');
        });
    });
  }

  unpublishContrat = (value) => {
    console.log("unpublish: " + value);
    this.setState({loading:true});

    return new Promise((resolve, reject) => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/unpublish?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/unpublish';
      fetch(urlServer, {
        method: 'POST',
        body: JSON.stringify({id:value}),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .then(json => {
          console.log(json);
          if (json.Response === 'True') {
            let contrats = this.state.contrats;
            contrats.map((item,index) => {
              if (item.action === value) {
                item.visible = 0;
              }
            });
            resolve();
            this.setState({contrats:contrats,loading:false});
          } else {
            reject(json.Error);
            this.setState({loading:false});
          }
        })
        .catch(err => {
          console.log(err);
          this.setState({loading:false});
          reject('api error');
        });
    });
  }

  correctLogin = (username) => {
    this.setState({username:username,loading:true});
    sessionStorage.setItem('username',username);
    this.getContrats();
    this.getCategories();
    this.getUnities();
  }

  render() {
    console.log(this.state.contrats);
    let listContrats = null;
    if (this.state.orderDir === "desc") {
      listContrats = this.state.contrats.filter((item,index) => {
        return (item.titulo.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.lastUpdate.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
          item.referencia.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.categoria.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
          item.unidad.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.url.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1);
      }).sort((a, b) => b[this.state.orderField].toString().localeCompare(a[this.state.orderField])).map((item, index) => (
        <ListContrats key={index} index={index} contrat={item} edita_cont={(value) => this.showEditContrat(value)} borrar_cont={(value) => this.showDeleteContract(value)} clonar_cont={(value) => this.showCloneContract(value)} publish_cont={(value) => this.publishContrat(value)} unpublish_cont={(value) => this.unpublishContrat(value)} />
      ));
    } else {
      listContrats = this.state.contrats.filter((item,index) => {
        return (item.titulo.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.lastUpdate.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
          item.referencia.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.categoria.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || 
          item.unidad.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1 || item.url.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1);
      }).sort((a, b) => a[this.state.orderField].toString().localeCompare(b[this.state.orderField])).map((item, index) => (
        <ListContrats key={index} index={index} contrat={item} edita_cont={(value) => this.showEditContrat(value)} borrar_cont={(value) => this.showDeleteContract(value)} clonar_cont={(value) => this.showCloneContract(value)} publish_cont={(value) => this.publishContrat(value)} unpublish_cont={(value) => this.unpublishContrat(value)} />
      ));
    }

    return (
      <>
      {this.state.username === '' ? (
        <Login correctUser={(username) => this.correctLogin(username)} serverRoute={this.props.serverRoute} />
      ) : (
        <>
        <div className="header-buttons">
          <div className="form-search">
            <input type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearch} />
            <span className="total-results-search">{listContrats.length} artículos encontrados</span>
          </div>
          <div className="button-add-contract" onClick={() => this.showAddContract()}>Crear nuevo contrato</div>
        </div>
        <ListContratsHeader orderField={this.state.orderField} orderDir={this.state.orderDir} changeOrder={(order,previousOrder) => this.changeOrder(order,previousOrder)} />
        <div className="content-resultrows">
          {listContrats}
        </div>
        {this.state.loading ? (
          <Loading />
          ) : null
        }
        {this.state.showAddContract ? (
          <AddContract closeAddContract={() => this.setState({showAddContract:false})} showPreview={(content) => this.showPreview(content)} saveContract={(content) => this.saveContract(content)} categories={this.state.categories} unities={this.state.unities} />
          ) : null
        }
        {this.state.preview ? (
          <PreviewContrat closePreviewContract={() => this.setState({preview:null})} content={this.state.preview} />
        ) : null}
        {this.state.showEditContract ? (
          <EditContract serverRoute={this.props.serverRoute} contract={this.state.contractSelected} closeEditContract={() => this.setState({showEditContract:false})} showPreview={(content) => this.showPreview(content)} saveContract={(content) => this.saveContract(content)} categories={this.state.categories} unities={this.state.unities} />
          ) : null
        }
        {this.state.showCloneContract ? (
          <CloneContract contract={this.state.contractSelected} closeCloneContract={() => this.setState({showCloneContract:false})} showPreview={(content) => this.showPreview(content)} saveContract={(content) => this.saveContract(content)} categories={this.state.categories} unities={this.state.unities} />
          ) : null
        }
        {this.state.showDeleteContract ? (
          <DeleteContract contract={this.state.contractToDeleted} closeBorrarContract={() => this.setState({showDeleteContract:false,contractToDeleted:null})} deleteContract={(value) => this.deleteContract(value)} />
          ) : null
        }
        {this.state.showAlerts ? (
          <Alerts message={this.state.alertMessage} closeAlert={() => this.setState({showAlerts:false,alertMessage:''})} />
          ) : null
        }
        </>
      )}
      </>
    );
  }
}

export default App;
