import React from 'react';

const ListDictionaryHeader = ({orderField, orderDir, changeOrder}) => {
    return (
      <div className="resultsrow header">
        <div className="resultsrow-in">
          <div className="resultsrow-column dictionary link" onClick={() => changeOrder('url',orderField)}>
            <div className="resultsrow-title">URL 
              {orderField === 'url' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'url' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column dictionary link" onClick={() => changeOrder('title',orderField)}>
            <div className="resultsrow-title">Título 
              {orderField === 'title' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'title' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column dictionary link" onClick={() => changeOrder('text',orderField)}>
            <div className="resultsrow-title">Texto 
              {orderField === 'text' && orderDir === "desc" ? 
                (<span>&#9660;</span>) : 
                ( orderField === 'text' && orderDir === "asc" ? 
                  (<span>&#9650;</span>) : null ) }
            </div>
          </div>
          <div className="resultsrow-column dictionary">
            <div className="resultsrow-title">Añadidas</div>
          </div>
        </div>
      </div>
    );

}

export default ListDictionaryHeader;
