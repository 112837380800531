import React from 'react';
import './styles.css';

const DeletePressNote = ({ pressnote, closeBorrarPressNote, deletePressNote }) => {
    return (
      <div className="borrar-contrat">
        <div className="borrar-contrat-in">
          <div className="borrar-contrat-in-title">¿Estás seguro de que quieres borrar esta Nota de Prensa?</div>
          <div className="borrar-contrat-in-buttons">
            <div className="borrar-contrat-in-button-yes" onClick={() => deletePressNote(pressnote)}>Sí</div>
            <div className="borrar-contrat-in-button-no" onClick={() => closeBorrarPressNote()}>No</div>
          </div>
        </div>
      </div>
    );

}

export default DeletePressNote;
