import React from 'react';
import './styles.css';

const Header = ({ username, usertype, userview, closeSession, changeUserView }) => {
    console.log(userview);
    let views = [{id: 2, name:'Contratos'},{id:3, name:'Notas de prensa'},{id:4,name:'Ofertas de Empleo'},{id:5,name:'Buscador web'}];
    let comboviews = views.filter((item, index) => {
        return(item.id !== parseInt(userview));
    }).map((item, index) => (
        <span key={index} onClick={() => changeUserView(item.id)}>{item.name}</span>
    ));
    let combotitle = views.filter((item, index) => {
        return(item.id === parseInt(userview));
    }).map((item, index) => (
        item.name
    ));
    return (
        <div className="top-header">
            <div className="top-header-in">
                <img src="https://www.arsys.es/img/shared/logo.png?=CV-1047" />
                {parseInt(usertype) === 1 ? 
                (
                    <>
                    <div className="top-header-in-combo">
                        <span>{combotitle}</span>
                        <svg className="open-select" viewBox="0 0 24 24"><path d="M7,10L12,15L17,10H7Z" /></svg>
                        <div className="top-subcombo">
                            {comboviews}
                        </div>
                    </div>
                    </>
                )
                :
                (
                    <span>{combotitle}</span>
                )
                }
                <div className="logged-as">Logged as <strong>{username}</strong> <span onClick={() => closeSession()} title="Cerrar sesión">&#128274;</span></div>
            </div>
        </div>
    );

}

export default Header;
