import React from 'react';
import PreviewPressNoteArsys from './PreviewPressNoteArsys';
import './styles.css';

const PreviewPressNote = ({ closePreviewPressNote, content }) => {
    return (
        <div className="preview-pressnote">
        <div className="preview-pressnote-in">
          <div className="preview-pressnote-close" onClick={() => closePreviewPressNote()}></div>
          <div className="preview-pressnote-content">
            <PreviewPressNoteArsys content={content} />
          </div>
		</div>
        </div>
    );

}

export default PreviewPressNote;