import React, { Component } from 'react';
import Loading from '../../Loading';
import './styles.css';

class AddWords extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wordOutput: this.props.word,
            showLoading: false,
            initialAdded:this.props.word.added
        }
    }

    handleKeyPressEditorWord = (event) => {
        if (event.which === 13) {
            event.preventDefault();
        }
    }

    handleChangeEditorWord = (event) => {
        let wordOutput = this.state.wordOutput;
        wordOutput.added = event.target.value;
        this.setState({wordOutput:wordOutput});
    }

    closeAddWords = () => {
      let wordOutput = this.state.wordOutput;
      wordOutput.added = this.state.initialAdded;
      this.setState({wordOutput:wordOutput}, () => {
        this.props.closeAddWords()
      });
    }

    render() {
          return (
              <div className="addword-background">
          <div className="addword-background-in">
            <div className="addword-background-in-header">Añadir palabras al Diccionario</div>
            <div className="addword-close" onClick={this.closeAddWords}></div>
            <div className="addword-content">
                <div className="addword-content-buttons">
                  <div className="addword-content-buttons-save" onClick={() => this.props.saveDictionary(this.state.wordOutput)} title="Guardar Palabras">
                    <svg viewBox="0 0 24 24">
                      <path d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" />
                    </svg>
                  <span>Guardar Palabras</span>
                  </div>
                </div>
                {this.state.wordOutput ? (
                <div className="addword-content-docoutput">
                  <div className="addword-content-title">
                    <div className="addword-content-title-text">Url:</div>
                    <div className="addword-content-title-value" dangerouslySetInnerHTML = { { __html: this.state.wordOutput.url } }></div>
                  </div>
                  <div className="addword-content-title">
                    <div className="addword-content-title-text">Título:</div>
                    <div className="addword-content-title-value" dangerouslySetInnerHTML = { { __html: this.state.wordOutput.title } }></div>
                  </div>
                  <div className="addword-content-title">
                    <div className="addword-content-title-text">Texto:</div>
                    <div className="addword-content-title-value" dangerouslySetInnerHTML = { { __html: this.state.wordOutput.text } }></div>
                  </div>
                  <div className="addword-content-title">
                    <div className="addword-content-title-text">Palabras añadidas:</div>
                    <div className="addword-content-title-value">
                        <textarea onKeyPress={this.handleKeyPressEditorWord} onChange={this.handleChangeEditorWord} value={this.state.wordOutput.added}></textarea>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {this.state.showLoading ? <Loading /> : null}
          </div>
        </div>
          );
    }

}
  
export default AddWords;