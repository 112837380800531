import React, { Component } from 'react';
import Loading from '../../Loading';
import { Editor } from '@tinymce/tinymce-react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './styles.css';
import {getEditorTinyApiKey} from '../../Lib/functions';

class AddPressNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: null,
            docOutput: {id:0,titulo:'',description:'',keywords:'',pdf:'',lastUpdate:'',author:'',date:'',content:'',url:'',publicado:false},
            showButtons: false,
            showImport:false,
            showLoading: false,
            selectedPdf: null
        }
    }

    handleFileSelect = (event) => {
        if (event.target.files[0]) {
            this.setState({ docOutput: null, messages: null, showLoading: true, showButtons: false });
            this.readFileInputEventAsArrayBuffer(event, (arrayBuffer) => {
                var mammoth = require("mammoth");
                console.log(arrayBuffer);
                /*var options = {
                    styleMap: [
                        "p[font-size='16'] => em"
                    ]
                };*/
                mammoth.convertToHtml({ arrayBuffer: arrayBuffer })
                    .then((result, error) => {
                        if (error) {
                            console.error(error)
                        } else {
                            this.displayResult(result);
                        }
                    })
                    .done();
            });
        }
    }

    displayResult = (result) => {
        var messageHtml = result.messages.map((message) => {
            return '<li className="' + message.type + '">' + this.escapeHtml(message.message) + "</li>";
        }).join("");
        result.value = result.value.replace(/http\:\/\/www\.arsys/g, 'https\:\/\/www\.arsys');
        result.value = result.value.replace(/http\:\/\/www\.piensasolutions/g, 'https\:\/\/www\.piensasolutions');
        result.value = result.value.replace(/ www\.arsys/g, ' https\:\/\/www\.arsys');
        result.value = result.value.replace(/ www\.piensasolutions/g, ' https\:\/\/www\.piensasolutions');
        result.value = result.value.replace(/>www\.arsys/g, '>https\:\/\/www\.arsys');
        result.value = result.value.replace(/>www\.piensasolutions/g, '>https\:\/\/www\.piensasolutions');
        result.value = this.urlify(result.value);
        let docOutput = {id:0,titulo:'',description:'',keywords:'',pdf:'',lastUpdate:'',author:'',date:'',content:result.value,url:'',publicado:false};
        this.setState({ docOutput: docOutput, messages: messageHtml, showLoading: false, showButtons: true });
    }

    readFileInputEventAsArrayBuffer = (event, callback) => {
        var file = event.target.files[0];

        var reader = new FileReader();

        reader.onload = (loadEvent) => {
            var arrayBuffer = loadEvent.target.result;
            callback(arrayBuffer);
        };

        reader.readAsArrayBuffer(file);
    }

    escapeHtml = (value) => {
        return value
            .replace(/&/g, '&amp;')
            .replace(/"/g, '&quot;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
    }

    closeWarnings = () => {
        this.setState({ messages: null });
    }

    handleChangeEditorPressNote = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.content = event.target.getContent();
      this.setState({docOutput:docOutput});
    }

    handleChangeTitle = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.titulo = event.target.value;
      this.setState({docOutput:docOutput});
    }

    handleChangeDescription = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.description = event.target.value;
      this.setState({docOutput:docOutput});
    }

    handleChangeKeywords = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.keywords = event.target.value;
      this.setState({docOutput:docOutput});
    }

    handleChangeDate = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.date = event;
      this.setState({docOutput:docOutput});
    }

    handleChangeUrl = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.url = event.target.value;
      this.setState({docOutput:docOutput});
    }

    handleChangePdf = (event) => {
      let docOutput = this.state.docOutput;
      if (event.target.files[0]) {
        docOutput.pdf = event.target.files[0].name;
      } else {
        docOutput.pdf = null;
      }
      this.setState({docOutput:docOutput,selectedPdf:event.target.files[0]});
    }

    openNewDoc = () => {
      this.setState({ docOutput: {id:0,titulo:'',description:'',keywords:'',pdf:'',lastUpdate:'',author:'',date:'',content:'',url:'',publicado:false}, messages: null, showLoading: false, showButtons: true });
    }

    urlify = (text) => {
      var urlRegex = /(\b (https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
      return text.replace(urlRegex, function(url) {
          return ' <a href="' + url.trim() + '">' + url.trim() + '</a>';
      })
        // or alternatively
        // return text.replace(urlRegex, '<a href="$1">$1</a>')
    }
    
    showImportPressNote = () => {
      this.setState({showImport: true});
    }

    hideImportPressNote = () => {
      this.setState({showImport: false});
    }

    render() {
        return (
            <div className="addcontract-background">
        <div className="addcontract-background-in">
        <div className="editcontract-background-in-header">Crear nueva Nota de Prensa</div>
          <div className="addcontract-close" onClick={() => this.props.closeAddPressNote()}></div>
          <div className="addcontract-content">
              {/*<div className="addcontract-content-button" onClick={() => this.openNewDoc()}>Abrir documento en blanco</div>*/}
              {this.state.showImport ? (
              <div className="addcontract-content-button">
                <div className="close" onClick={() => this.hideImportPressNote()}>x</div>
                <svg viewBox="0 0 24 24">
                  <path d="M14,12L10,8V11H2V13H10V16M20,18V6C20,4.89 19.1,4 18,4H6A2,2 0 0,0 4,6V9H6V6H18V18H6V15H4V18A2,2 0 0,0 6,20H18A2,2 0 0,0 20,18Z" />
                </svg>
                <div className="addcontract-content-title">Selecciona el documento docx:</div>
                <div className="addcontract-content-input"><input type="file" name="docxfile" accept=".doc, .docx" onChange={this.handleFileSelect} /></div>
              </div>
              ) : (
              <div className="importcontract-content-buttons-save" onClick={() => this.showImportPressNote()} title="Importar desde .docx">
                <svg viewBox="0 0 24 24">
                  <path d="M14,12L10,8V11H2V13H10V16M20,18V6C20,4.89 19.1,4 18,4H6A2,2 0 0,0 4,6V9H6V6H18V18H6V15H4V18A2,2 0 0,0 6,20H18A2,2 0 0,0 20,18Z" />
                </svg>
                <span>Importar desde .docx</span>
              </div>
              )}
            {/*<div className="addcontract-content-button" onClick={() => this.handleFileSelect()}>Enviar</div>*/}
              <div className="addcontract-content-buttons pressnotes">
                <div className="addcontract-content-buttons-save" onClick={() => this.props.savePressNote(this.state.docOutput, this.state.selectedPdf)} title="Guardar Nota de Prensa">
                  <svg viewBox="0 0 24 24">
                    <path d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" />
                  </svg>
                  <span>Guardar Nota de Prensa</span>
                </div>
                <div className="addcontract-content-buttons-preview" onClick={() => this.props.showPreview(this.state.docOutput)} title="Vista previa">
                  <svg viewBox="0 0 24 24">
                    <path d="M9,13A3,3 0 0,0 12,16A3,3 0 0,0 15,13A3,3 0 0,0 12,10A3,3 0 0,0 9,13M20,19.59V8L14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18C18.45,22 18.85,21.85 19.19,21.6L14.76,17.17C13.96,17.69 13,18 12,18A5,5 0 0,1 7,13A5,5 0 0,1 12,8A5,5 0 0,1 17,13C17,14 16.69,14.96 16.17,15.75L20,19.59Z" />
                  </svg>
                  <span>Vista previa</span>
                </div>
              </div>
            {this.state.docOutput ? (
              <div className="addcontract-content-docoutput pressnotes">
                <div className="addcontract-content-title">
                  <div className="addcontract-content-title-text">Título Nota de Prensa:</div>
                  <div className="addcontract-content-title-value"><input type="text" onChange={this.handleChangeTitle} placeholder="Título Nota de Prensa" /></div>
                </div>
                <div className="addcontract-content-title">
                  <div className="addcontract-content-title-text">Meta Description:</div>
                  <div className="addcontract-content-title-value"><input type="text" onChange={this.handleChangeDescription} placeholder="Meta Description" /></div>
                </div>
                <div className="addcontract-content-title">
                  <div className="addcontract-content-title-text">Meta Keywords:</div>
                  <div className="addcontract-content-title-value"><input type="text" onChange={this.handleChangeKeywords} placeholder="Meta Keywords" /></div>
                </div>
                <div className="addcontract-content-title">
                  <div className="addcontract-content-title-text">Fecha Nota de Prensa:</div>
                  <div className="addcontract-content-title-value"><DatePicker dateFormat="yyyy/MM/dd" placeholderText="Fecha Nota de Prensa" selected={this.state.docOutput.date} onChange={this.handleChangeDate} /></div>
                </div>
                <div className="addcontract-content-title">
                  <div className="addcontract-content-title-text">Url Nota de Prensa:</div> 
                  <div className="addcontract-content-title-value">
                  <label htmlFor="url">https://www.arsys.es/empresa/prensa/</label>
                    <input type="text" id="url" onChange={this.handleChangeUrl} placeholder="Url Nota de Prensa" />
                  </div>
                  <input name="image" type="file" id="upload" className="hidden"></input>
                </div>
                <div className="addcontract-content-title">
                  <div className="addcontract-content-title-text">PDF Nota de Prensa:</div> 
                  <div className="addcontract-content-title-value">
                    <input name="pdffile" type="file" onChange={this.handleChangePdf}></input>
                  </div>
                </div>

                <div className="addcontract-content-contract">
                  <div className="editcontract-content-contract-title">
                  Texto Nota de Prensa:
                  </div>
                  <Editor apiKey={getEditorTinyApiKey()}
                          key="contratarsys"
                          initialValue={this.state.docOutput.content}
                          init={{
                            content_style: "html {min-width: 100%;scrollbar-color: #bbb #ddd;scrollbar-width: thin;} " + 
                                              "* {font-size: 14px;}" +
                                              "body {text-align: left;padding: 15px;}" +
                                              "p {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;margin-bottom:8px;margin-left:0px;margin-right:30px;margin-top:8px;padding:0;text-align:left;} " + 
                                              "h1 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:32px;font-weight:500;letter-spacing:0.3px;line-height:40px;margin-bottom:24px;margin-left:0px;margin-right:0px;margin-top:48px;padding-bottom:8px;padding:8px 0px;text-align:left;text-transform:uppercase;}" +
                                              "h2 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:24px;font-weight:500;letter-spacing:0.3px;line-height:40px;margin-bottom:0px;margin-left:0px;margin-right:0px;margin-top:0px;padding:0;text-align:left;text-transform:uppercase;} " +
                                              "h3 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:20px;font-weight:500;letter-spacing:-1px;line-height:24px;margin-bottom:0px;margin-left:0px;margin-right:0px;margin-top:16px;padding:0;text-align:left;text-transform:uppercase;}" +
                                              "h4 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:20px;font-weight:500;letter-spacing:-1px;line-height:24px;margin-bottom:16px;margin-left:0px;margin-right:0px;margin-top:16px;padding:0px;text-align:left;text-transform:capitalize;}" +
                                              "h5, h6 {color:rgb(50, 50, 50);display:block;font-family:os, sans-serif;font-size:16px;font-weight:700;line-height:16px;margin:0px;padding:0px;text-align:left;text-transform:none;}" + 
                                              "ul {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-type:circle;margin:16px 24px;padding:0;text-align:left;}" +
                                              "ol {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-type:decimal;margin-bottom:16px;margin-left:24px;margin-right:24px;margin-top:16px;padding:0px;text-align:left;}" +
                                              "li {color:rgb(55, 55, 55);display:list-item;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-image:none;list-style-position:outside;margin-bottom:0px;margin-left:35px;margin-right:0px;margin-top:0px;padding:0;text-align:left;}" +
                                              "table, tr, td {border: 1px solid #000;}" + 
                                              "table tr td {padding: 10px 15px;}" +
                                              "br {display: block;}" +
                                              "::-webkit-scrollbar {width: 8px;height:8px;}" +
                                              "::-webkit-scrollbar-thumb {background: #bbb;border-radius: 10px;}" +
                                              "::-webkit-scrollbar-track {background: #ddd;}",
                            content_css : "https://www.arsys.es/resource/style/a.css,https://www.arsys.es/resource/style/legal.css,https://www.arsys.es/resource/style/empresa.css",
                            plugins: 'link image code paste lists searchreplace table',
                            menu: {},
                            toolbar: 'undo redo | formatselect | bold italic underline strikethrough | alignleft | aligncenter | alignright | bullist numlist | image | link | table | searchreplace | code',
                            table_default_attributes: {'border': '1'},
                            table_default_styles: {'border-collapse': 'collapse','width': '98%','border':'1px solid #000','border-spacing': '0'},
                            table_appearance_options: false,
                            table_responsive_width: true,

                            file_picker_callback: function(callback, value, meta) {
                              if (meta.filetype == 'image') {
                                document.getElementById('upload').click();
                                document.getElementById('upload').addEventListener('change', function() {
                                  var file = this.files[0];
                                  var reader = new FileReader();
                                  reader.onload = function(e) {
                                    callback(e.target.result, {
                                      alt: ''
                                    });
                                  };
                                  reader.readAsDataURL(file);
                                });
                              }
                            },

                            height: '480px',
                            paste_retain_style_properties: "",
                            paste_preprocess: function(plugin, args) {
                              args.content = args.content.replace(/http\:\/\/www\.arsys/g, 'https://www.arsys');
                              args.content = args.content.replace(/http\:\/\/www\.piensasolutions/g, 'https://www.piensasolutions');
                              args.content = args.content.replace(/ www\.arsys/g, ' https://www.arsys');
                              args.content = args.content.replace(/ www\.piensasolutions/g, ' https://www.piensasolutions');
                              args.content = args.content.replace(/>www\.arsys/g, '>https://www.arsys');
                              args.content = args.content.replace(/>www\.piensasolutions/g, '>https://www.piensasolutions');
                              args.content = args.content.replace(/<div>/g,'<p>');
                              args.content = args.content.replace(/<\/div>/g,'</p>');
                              var urlRegex = /(\b (https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                              args.content = args.content.replace(urlRegex, function(url) {
                                  return ' <a href="' + url.trim() + '">' + url.trim() + '</a>';
                              })
                            },
                            /*  paste_postprocess: function(plugin, args) {
    console.log(args.node);
    const nodelist = args.node.querySelectorAll('p');
const nodelistToArray = Array.apply(null, nodelist);
console.log(nodelistToArray);
  },*/
                            branding: false
                          }}
                          onChange={this.handleChangeEditorPressNote}
                        />
                </div>
              </div>
            ) : null}
            {this.state.messages ? (
              <div className="addcontract-content-messages">
                <div className="addcontract-content-messages-container">
                  <div className="addcontract-content-messages-close" onClick={() => this.closeWarnings()}>X</div>
                  <div className="addcontract-content-messages-title">Warnings:</div>
                  <ul dangerouslySetInnerHTML={{__html: this.state.messages}}></ul>
                </div>
              </div>
            ) : null}
          </div>
          {this.state.showLoading ? <Loading /> : null}
        </div>
      </div>
        );
    }
}

export default AddPressNote;