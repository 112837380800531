import React, { Component } from 'react';
import SitesTabs from './SitesTabs';
import ListDictionary from './ListDictionary';
import ListDictionaryHeader from './ListDictionaryHeader';
import AddWords from './AddWords';
import Loading from '../Loading';
import Login from '../Login';
import Alerts from '../Alerts';
import {authParams} from '../Lib/functions';

//import customData from './backWebSearch.json';

class App extends Component {
    constructor(props) {
      super(props);
      console.log(this.props.username);
      console.log(this.props.serverRoute);
      let username = this.props.username;
      if (sessionStorage.getItem('username')) {
        username = sessionStorage.getItem('username');
      }
      this.state = {
        dictionary:[],
        loading: false,
        orderField: 'url',
        orderDir: 'asc',
        search: '',
        username:username,
        showAlerts: false,
        site: 'es',
        wordSelected: null,
        showAddWords:false
      }
    }
  
    componentDidMount = () => {
      if (this.props.username || sessionStorage.getItem('username')) {
        this.setState({loading:true});
        this.getDictionary();
      }
    }

    
    getDictionary = () => {
        this.setState({loading : true});
        const dictionary = [];
        this.getDictionaryCall(this.state.site)
        .then(res => {
            console.log(res);
            JSON.parse(res.data).pages.forEach(item => {
                dictionary.push(item);
            })
        })
        .then(() => {
          this.getAddedWordsCall(this.state.site)
          .then(res => {
            JSON.parse(res.data).forEach(item => {
              dictionary.find(element => element.url === item.url).added = item.added;
            });
            this.setState({dictionary:dictionary,loading : false});
          })
        })
        .catch(error => {
            alert(error);
            this.setState({dictionary:[],loading : false});
        });
    }

    getDictionaryCall = (site) => {
        return new Promise((resolve, reject) => {
          var timestamp = new Date().getTime();
          let authParam = authParams(timestamp);
          let urlServer = this.props.serverRoute + '/dictionary?t='+ timestamp + '&k=' + authParam + '&id=' + site;
          //let urlServer = this.props.serverRoute + '/dictionary?id=' + site;
          fetch(urlServer)
            .then(res => res.json())
            .then(json => {
              if (json.Response === 'True') {
                resolve(json);
              } else {
                reject(json.Error);
              }
            })
            .catch(err => {
              console.log(err);
              reject('api error');
            });
            //resolve({"data":customData});
        });
    }

    getAddedWordsCall = (site) => {
      return new Promise((resolve, reject) => {
        var timestamp = new Date().getTime();
        let authParam = authParams(timestamp);
        let urlServer = this.props.serverRoute + '/added-words?t='+ timestamp + '&k=' + authParam + '&id=' + site;
        //let urlServer = this.props.serverRoute + '/added-words?id=' + site;
        fetch(urlServer)
          .then(res => res.json())
          .then(json => {
            if (json.Response === 'True') {
              resolve(json);
            } else {
              reject(json.Error);
            }
          })
          .catch(err => {
            console.log(err);
            reject('api error');
          });
          //resolve({"data":customData});
      });
    }

    handleSearch = (event) => {
        this.setState({search:event.target.value});
    }

    changeOrder = (order, previousOrder) => {
      console.log(order+' - '+previousOrder);
      let orderDir = this.state.orderDir;
      if (order === previousOrder) {
        if (orderDir === "asc") {
          orderDir = "desc";
        } else {
          orderDir = "asc";
        }
      } else {
          orderDir = "asc";
      }
      this.setState({orderDir:orderDir,orderField:order});
    }

    getAddWords = (value) => {
      this.setState({loading : true});
      let word = this.state.dictionary.filter((item,index) => {
        return (item.url === value);
      });
      this.setState({wordSelected:word[0],showAddWords:true,loading : false});
    }

    correctLogin = (username) => {
        this.setState({username:username,loading:true});
        sessionStorage.setItem('username',username);
        //this.getJobOffers();
    }

    saveAddWords = (word, pdf=null) => {
      this.setState({loading : true});
      let dictionary = this.state.dictionary;
      dictionary.filter((item,index) => {
        return (item.url === word.url);
      }).added = word.added;

      this.insertNewWordDictionary(dictionary)
      .then(res => {
        console.log(res);
        this.setState({dictionary:dictionary,showAddWords:false,loading:false})
      })
      .catch(error => {
        console.log(error);
        this.setState({showAlerts:true,alertMessage:'Se ha producido un error: '+error,loading:false})
      });
    }

    insertNewWordDictionary = (content) => {
      return new Promise((resolve, reject) => {
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/dictionary?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/dictionary';
        fetch(urlServer, {
          method: 'POST',
          body: JSON.stringify({site: this.state.site, data:content}),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(res => res.json())
          .then(json => {
            console.log(json);
            if (json.Response === 'True') {
              resolve(json);
            } else {
              reject(json.Error);
            }
          })
          .catch(err => {
            console.log(err);
            reject('api error');
          });
          //resolve(content.id);
      });
    }

    changeSite = (site) => {
      this.setState({site:site}, () => {
        this.getDictionary();
      });
    }

    render() {
        console.log(this.state.site);
        let listDictionary = null;
        if (this.state.orderDir === "desc") {
          listDictionary = this.state.dictionary.filter((item,index) => {
              return ((item.title && item.title.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) || (item.text && item.text.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) || 
              (item.url && item.url.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1));
          }).sort((a, b) => String(b[this.state.orderField]).localeCompare(a[this.state.orderField])).map((item, index) => (
            <ListDictionary key={index} index={index} page={item} add_words={(value) => this.getAddWords(value)} />
          ));
        } else {
          listDictionary = this.state.dictionary.filter((item,index) => {
              return ((item.title && item.title.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) || (item.text && item.text.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1) || 
              (item.url && item.url.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1));
          }).sort((a, b) => String(a[this.state.orderField]).localeCompare(b[this.state.orderField])).map((item, index) => (
            <ListDictionary key={index} index={index} page={item} add_words={(value) => this.getAddWords(value)} />
          ));
        }
        return (
          <>
          {this.state.username === '' ? (
            <Login correctUser={(username) => this.correctLogin(username)} serverRoute={this.props.serverRoute} />
          ) : (
            <>
            <div className="header-buttons">
              <div className="form-search">
                <input className="form-search-dictionary" type="text" placeholder="Search" value={this.state.search} onChange={this.handleSearch} />
                <span className="total-results-search total-results-search-dictionary">{listDictionary.length} artículos encontrados</span>
              </div>
              <SitesTabs site={this.state.site} selectSite={(value) => this.changeSite(value)} />
            </div>
            <ListDictionaryHeader orderField={this.state.orderField} orderDir={this.state.orderDir} changeOrder={(order,previousOrder) => this.changeOrder(order,previousOrder)} />
            <div className="content-resultrows">
              {listDictionary}
            </div>
            {this.state.loading ? (
              <Loading />
              ) : null
            }
            {this.state.showAddWords ? (
              <AddWords word={this.state.wordSelected} saveDictionary={(value) => this.saveAddWords(value)} closeAddWords={() => this.setState({showAddWords:false})} />
              ) : null
            }
            {this.state.showAlerts ? (
              <Alerts message={this.state.alertMessage} closeAlert={() => this.setState({showAlerts:false,alertMessage:''})} />
              ) : null
            }
            </>
          )}
          </>
        );
    }
}
    
export default App;

