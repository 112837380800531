import React, { Component } from 'react';
import Loading from '../../Loading';
import { Editor } from '@tinymce/tinymce-react';
import './styles.css';
import {getEditorTinyApiKey} from '../../Lib/functions';

class CloneContract extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docOutput: this.props.contract,
            showLoading: false
        }
    }

    handleChangeEditorContract = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.textNotVar = event.target.getContent();
      this.setState({docOutput:docOutput});
    }

    handleChangeEditorHeaderWith = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.cabeceraVar = event.target.getContent();
      this.setState({docOutput:docOutput});
    }

    handleChangeEditorHeaderWithOut = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.cabeceraNotVar = event.target.getContent();
      this.setState({docOutput:docOutput});
    }

    handleChangeTitle = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.titulo = event.target.value;
      this.setState({docOutput:docOutput});
    }

    handleChangeReference = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.referencia = event.target.value;
      this.setState({docOutput:docOutput});
    }

    handleChangeUrl = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.url = event.target.value;
      this.setState({docOutput:docOutput});
    }

    handleChangeUnity = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.unidad = event.target.value;
      this.setState({docOutput:docOutput});
    }

    handleChangeCategory = (event) => {
      let docOutput = this.state.docOutput;
      docOutput.categoria = event.target.value;
      this.setState({docOutput:docOutput});
    }

    render() {
      let categories = this.props.categories.map((item, index) => (
        <option key={index} value={item.id}>{item.title}</option>
      ));
      let unities = this.props.unities.map((item, index) => (
        <option key={index} value={item.id}>{item.title}</option>
      ));
        return (
            <div className="editcontract-background">
        <div className="editcontract-background-in">
          <div className="editcontract-background-in-header">Clonar Contrato</div>
          <div className="editcontract-close" onClick={() => this.props.closeCloneContract()}></div>
          <div className="editcontract-content">
              <div className="editcontract-content-buttons">
                <div className="editcontract-content-buttons-save" onClick={() => this.props.saveContract(this.state.docOutput)} title="Guardar contrato">
                  <svg viewBox="0 0 24 24">
                    <path d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" />
                  </svg>
                  <span>Guardar contrato</span>
                </div>
                <div className="editcontract-content-buttons-preview" onClick={() => this.props.showPreview(this.state.docOutput)} title="Vista previa">
                  <svg viewBox="0 0 24 24">
                    <path d="M9,13A3,3 0 0,0 12,16A3,3 0 0,0 15,13A3,3 0 0,0 12,10A3,3 0 0,0 9,13M20,19.59V8L14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18C18.45,22 18.85,21.85 19.19,21.6L14.76,17.17C13.96,17.69 13,18 12,18A5,5 0 0,1 7,13A5,5 0 0,1 12,8A5,5 0 0,1 17,13C17,14 16.69,14.96 16.17,15.75L20,19.59Z" />
                  </svg>
                  <span>Vista previa</span>
                </div>
              </div>
              <div className="editcontract-content-docoutput">
                <div className="editcontract-content-title">
                  <div className="editcontract-content-title-text">Título contrato:</div>
                  <div className="editcontract-content-title-value"><input type="text" value={this.state.docOutput.titulo} onChange={this.handleChangeTitle} /></div>
                </div>
                <div className="editcontract-content-title">
                  <div className="editcontract-content-title-text">Referencia:</div> 
                  <div className="editcontract-content-title-value"><input type="text" value={this.state.docOutput.referencia} onChange={this.handleChangeReference} /></div>
                </div>
                <div className="editcontract-content-title">
                  <div className="editcontract-content-title-text">Nombre del archivo:</div> 
                  <div className="editcontract-content-title-value"><input type="text" value={this.state.docOutput.url} onChange={this.handleChangeUrl} /></div>
                </div>
                <div className="editcontract-content-title">
                  <div className="editcontract-content-title-text">Unidad:</div> 
                 <div className="editcontract-content-title-value">
                   <select onChange={this.handleChangeUnity} value={this.state.docOutput.unidad}>
                      {unities}
                    </select>
                  </div>
                </div>
                <div className="editcontract-content-title">
                  <div className="editcontract-content-title-text">Categoría:</div> 
                  <div className="editcontract-content-title-value">
                    <select onChange={this.handleChangeCategory} value={this.state.docOutput.categoria}>
                      {categories}
                    </select>
                  </div>
                </div>
                <div className="editcontract-content-title">
                  <div className="editcontract-content-title-text">Última actualización:</div> 
                  <div className="editcontract-content-title-value">{this.state.docOutput.lastUpdate}</div>
                </div>
                <div className="editcontract-content-headers">
                  <div className="editcontract-content-headers-without">
                    <div className="editcontract-content-headers-title">
                      Cabecera SIN variables que van en la Web:
                    </div>
                    {parseInt(this.state.docOutput.unidad) === 2 ? (
                    <Editor apiKey={getEditorTinyApiKey()}
                            key="cabeceranotvarpiensa"
                            initialValue={this.state.docOutput.cabeceraNotVar}
                            init={{
                              content_style: "html {min-width: 100%;scrollbar-color: #bbb #ddd;scrollbar-width: thin;} " + 
                                                "body {text-align: left;padding: 15px;background: #fff;min-width: 100%;}" +
                                                "p {color:rgb(79, 79, 79);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:-0.28px;line-height:20px;margin-bottom:15px;margin-left:0px;margin-right:30px;margin-top:0px;padding:0px;text-align:left;text-decoration-color:rgb(79, 79, 79);text-decoration-line:none;text-decoration-style:solid;text-shadow:rgba(0, 0, 0, 0.3) 0px 0px 1px;}" +
                                                "a {text-decoration: underline;color: #000000;}" +
                                                "h1 {color:rgb(234, 91, 12);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:22px;font-style:normal;font-weight:400;margin:14.74px 0px;padding:0px;text-align:left;}" +
                                                "h2 {color:rgb(234, 91, 12);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:18px;font-style:normal;font-weight:400;margin-bottom:15px;margin-left:0px;margin-right:0px;margin-top:25px;padding:0px;text-align:left;}" +
                                                "h3 {color:rgb(234, 91, 12);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:16px;font-style:normal;font-weight:400;margin:15px 0px;padding:0px;text-align:left;}" +
                                                "h4, h5 {color:rgb(234, 91, 12);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;margin:0px;padding:0px;text-align:left;}" +
                                                "h6 {color:rgb(79, 79, 79);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:16px;font-style:normal;font-weight:400;margin:0px;padding:0px;text-align:left;}" +
                                                "ul {color:rgb(79, 79, 79);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;list-style-image:none;list-style-position:outside;list-style-type:disc;margin-bottom:20px;margin-left:30px;margin-right:0px;margin-top:20px;padding:0px;text-align:left;}" +
                                                "ol {color:rgb(79, 79, 79);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;list-style-image:none;list-style-position:outside;list-style-type:decimal;margin-bottom:20px;margin-left:30px;margin-right:0px;margin-top:20px;padding:0px;text-align:left;}" +
                                                "li {color:rgb(79, 79, 79);display:list-item;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;margin:0px;padding:0px;text-align:left;}" +
                                                "::-webkit-scrollbar {width: 8px;height:8px;}" +
                                                "::-webkit-scrollbar-thumb {background: #bbb;border-radius: 10px;}" +
                                                "::-webkit-scrollbar-track {background: #ddd;}",
                              /*content_css : "https://www.piensasolutions.com/css/piensa.css,https://www.piensasolutions.com/css/ps.legal.css",*/
                              plugins: 'link image code paste lists searchreplace',
                              menu: {},
                              toolbar: 'undo redo | formatselect | bold italic underline strikethrough | bullist numlist | image | link | searchreplace | code',
                              height: '300px',
                              paste_retain_style_properties: "",
                              paste_preprocess: function(plugin, args) {
                                args.content = args.content.replace(/http\:\/\/www\.arsys/g, 'https://www.arsys');
                                args.content = args.content.replace(/http\:\/\/www\.piensasolutions/g, 'https://www.piensasolutions');
                                args.content = args.content.replace(/ www\.arsys/g, ' https://www.arsys');
                                args.content = args.content.replace(/ www\.piensasolutions/g, ' https://www.piensasolutions');
                                args.content = args.content.replace(/>www\.arsys/g, '>https://www.arsys');
                                args.content = args.content.replace(/>www\.piensasolutions/g, '>https://www.piensasolutions');
                                args.content = args.content.replace(/<div>/g,'<p>');
                                args.content = args.content.replace(/<\/div>/g,'</p>');
                                var urlRegex = /(\b (https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                                args.content = args.content.replace(urlRegex, function(url) {
                                    return ' <a href="' + url.trim() + '">' + url.trim() + '</a>';
                                })
                              },
                              branding: false
                            }}
                            onChange={this.handleChangeEditorHeaderWithOut}
                          />
                    ) : (
                    parseInt(this.state.docOutput.unidad) === 3 ? (
                    <Editor apiKey={getEditorTinyApiKey()}
                            key="cabeceranotvarnicline"
                            initialValue={this.state.docOutput.cabeceraNotVar}
                            init={{
                              content_style: "html {min-width: 100%;scrollbar-color: #bbb #ddd;scrollbar-width: thin;} " + 
                                                "body {text-align: left;padding: 15px;background: #fff;}" +
                                                "p {font-size: 1.1em;margin: 0 1em 1em 1.8em;}" +
                                                "h1 {color: #af0b12;font-size: 1.2em;background: #f1eee4 ;border-top: 1px solid #bdb8a8;border-bottom: 1px solid #bdb8a8;margin: 1.5em .2em .6em .2em;padding: .2em .2em .2em .4em;}" +
                                                "h2 {color: #000;font-size: 1.2em;font-weight: bold;margin: 1em .2em .6em .2em;}" +
                                                "h3 {color: #af0b12;font-size: 1.2em;font-weight: bold;margin: .4em 0 .1em 1.4em;}" +
                                                "ul {margin: 0 0 .8em 3.2em;font-size: 1.1em;}" +
                                                "li {list-style: none;margin: 0 0 .4em 0;font-size: 12px;}" +
                                                "li:before {content: '\\00bb';margin: 0 1em;color:orange;font-size: 15px;line-height: 12px;font-weight: bold;}" +
                                                "::-webkit-scrollbar {width: 8px;height:8px;}" +
                                                "::-webkit-scrollbar-thumb {background: #bbb;border-radius: 10px;}" +
                                                "::-webkit-scrollbar-track {background: #ddd;}",
                              /*content_css : "http://www.nicline.com/css/styles.css",*/
                              plugins: 'link image code paste lists searchreplace',
                              menu: {},
                              toolbar: 'undo redo | formatselect | bold italic underline strikethrough | bullist numlist | image | link | searchreplace | code',
                              height: '300px',
                              paste_retain_style_properties: "",
                              paste_preprocess: function(plugin, args) {
                                args.content = args.content.replace(/http\:\/\/www\.arsys/g, 'https://www.arsys');
                                args.content = args.content.replace(/http\:\/\/www\.piensasolutions/g, 'https://www.piensasolutions');
                                args.content = args.content.replace(/ www\.arsys/g, ' https://www.arsys');
                                args.content = args.content.replace(/ www\.piensasolutions/g, ' https://www.piensasolutions');
                                args.content = args.content.replace(/>www\.arsys/g, '>https://www.arsys');
                                args.content = args.content.replace(/>www\.piensasolutions/g, '>https://www.piensasolutions');
                                args.content = args.content.replace(/<div>/g,'<p>');
                                args.content = args.content.replace(/<\/div>/g,'</p>');
                                var urlRegex = /(\b (https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                                args.content = args.content.replace(urlRegex, function(url) {
                                    return ' <a href="' + url.trim() + '">' + url.trim() + '</a>';
                                })
                              },
                              branding: false
                            }}
                            onChange={this.handleChangeEditorHeaderWithOut}
                          />
                    ) : (
                    <Editor apiKey={getEditorTinyApiKey()}
                            key="cabeceranotvararsys"
                            initialValue={this.state.docOutput.cabeceraNotVar}
                            init={{
                              content_style: "html {min-width: 100%;scrollbar-color: #bbb #ddd;scrollbar-width: thin;} " + 
                                              "* {font-size: 14px;}" +
                                              "body {text-align: left;padding: 15px;}" +
                                              "p {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;margin-bottom:8px;margin-left:0px;margin-right:30px;margin-top:8px;padding:0;text-align:left;} " + 
                                              "h1 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:32px;font-weight:500;letter-spacing:0.3px;line-height:40px;margin-bottom:24px;margin-left:0px;margin-right:0px;margin-top:48px;padding-bottom:8px;padding:8px 0px;text-align:left;text-transform:uppercase;}" +
                                              "h2 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:24px;font-weight:500;letter-spacing:0.3px;line-height:40px;margin-bottom:0px;margin-left:0px;margin-right:0px;margin-top:0px;padding:0;text-align:left;text-transform:uppercase;} " +
                                              "h3 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:20px;font-weight:500;letter-spacing:-1px;line-height:24px;margin-bottom:0px;margin-left:0px;margin-right:0px;margin-top:16px;padding:0;text-align:left;text-transform:uppercase;}" +
                                              "h4 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:20px;font-weight:500;letter-spacing:-1px;line-height:24px;margin-bottom:16px;margin-left:0px;margin-right:0px;margin-top:16px;padding:0px;text-align:left;text-transform:capitalize;}" +
                                              "h5, h6 {color:rgb(50, 50, 50);display:block;font-family:os, sans-serif;font-size:16px;font-weight:700;line-height:16px;margin:0px;padding:0px;text-align:left;text-transform:none;}" + 
                                              "ul {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-type:circle;margin:16px 24px;padding:0;text-align:left;}" +
                                              "ol {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-type:decimal;margin-bottom:16px;margin-left:24px;margin-right:24px;margin-top:16px;padding:0px;text-align:left;}" +
                                              "li {color:rgb(55, 55, 55);display:list-item;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-image:none;list-style-position:outside;margin-bottom:0px;margin-left:35px;margin-right:0px;margin-top:0px;padding:0;text-align:left;}" +
                                              "br {display: block;}" +
                                              "::-webkit-scrollbar {width: 8px;height:8px;}" +
                                              "::-webkit-scrollbar-thumb {background: #bbb;border-radius: 10px;}" +
                                              "::-webkit-scrollbar-track {background: #ddd;}",
                              content_css : "https://www.arsys.es/resource/style/a.css,https://www.arsys.es/resource/style/legal.css",
                              plugins: 'link image code paste lists searchreplace',
                              menu: {},
                              toolbar: 'undo redo | formatselect | bold italic underline strikethrough | bullist numlist | image | link | searchreplace | code',
                              height: '300px',
                              paste_retain_style_properties: "",
                              paste_preprocess: function(plugin, args) {
                                args.content = args.content.replace(/http\:\/\/www\.arsys/g, 'https://www.arsys');
                                args.content = args.content.replace(/http\:\/\/www\.piensasolutions/g, 'https://www.piensasolutions');
                                args.content = args.content.replace(/ www\.arsys/g, ' https://www.arsys');
                                args.content = args.content.replace(/ www\.piensasolutions/g, ' https://www.piensasolutions');
                                args.content = args.content.replace(/>www\.arsys/g, '>https://www.arsys');
                                args.content = args.content.replace(/>www\.piensasolutions/g, '>https://www.piensasolutions');
                                args.content = args.content.replace(/<div>/g,'<p>');
                                args.content = args.content.replace(/<\/div>/g,'</p>');
                                var urlRegex = /(\b (https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                                args.content = args.content.replace(urlRegex, function(url) {
                                    return ' <a href="' + url.trim() + '">' + url.trim() + '</a>';
                                })
                              },
                              branding: false
                            }}
                            onChange={this.handleChangeEditorHeaderWithOut}
                          />
                    ))}
                  </div>
                  <div className="editcontract-content-headers-with">
                    <div className="editcontract-content-headers-title">
                      Cabecera CON variables que van en la Contratación:
                    </div>
                    {parseInt(this.state.docOutput.unidad) === 2 ? (
                    <Editor apiKey={getEditorTinyApiKey()}
                            key="cabeceravarpiensa"
                            initialValue={this.state.docOutput.cabeceraVar}
                            init={{
                              content_style: "html {min-width: 100%;scrollbar-color: #bbb #ddd;scrollbar-width: thin;} " + 
                                                "body {text-align: left;padding: 15px;background: #fff;min-width: 100%;}" +
                                                "p {color:rgb(79, 79, 79);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:-0.28px;line-height:20px;margin-bottom:15px;margin-left:0px;margin-right:30px;margin-top:0px;padding:0px;text-align:left;text-decoration-color:rgb(79, 79, 79);text-decoration-line:none;text-decoration-style:solid;text-shadow:rgba(0, 0, 0, 0.3) 0px 0px 1px;}" +
                                                "a {text-decoration: underline;color: #000000;}" +
                                                "h1 {color:rgb(234, 91, 12);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:22px;font-style:normal;font-weight:400;margin:14.74px 0px;padding:0px;text-align:left;}" +
                                                "h2 {color:rgb(234, 91, 12);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:18px;font-style:normal;font-weight:400;margin-bottom:15px;margin-left:0px;margin-right:0px;margin-top:25px;padding:0px;text-align:left;}" +
                                                "h3 {color:rgb(234, 91, 12);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:16px;font-style:normal;font-weight:400;margin:15px 0px;padding:0px;text-align:left;}" +
                                                "h4, h5 {color:rgb(234, 91, 12);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;margin:0px;padding:0px;text-align:left;}" +
                                                "h6 {color:rgb(79, 79, 79);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:16px;font-style:normal;font-weight:400;margin:0px;padding:0px;text-align:left;}" +
                                                "ul {color:rgb(79, 79, 79);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;list-style-image:none;list-style-position:outside;list-style-type:disc;margin-bottom:20px;margin-left:30px;margin-right:0px;margin-top:20px;padding:0px;text-align:left;}" +
                                                "ol {color:rgb(79, 79, 79);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;list-style-image:none;list-style-position:outside;list-style-type:decimal;margin-bottom:20px;margin-left:30px;margin-right:0px;margin-top:20px;padding:0px;text-align:left;}" +
                                                "li {color:rgb(79, 79, 79);display:list-item;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;margin:0px;padding:0px;text-align:left;}" +
                                                "::-webkit-scrollbar {width: 8px;height:8px;}" +
                                                "::-webkit-scrollbar-thumb {background: #bbb;border-radius: 10px;}" +
                                                "::-webkit-scrollbar-track {background: #ddd;}",
                              /*content_css : "https://www.piensasolutions.com/css/piensa.css,https://www.piensasolutions.com/css/ps.legal.css",*/
                              plugins: 'link image code paste lists searchreplace',
                              menu: {},
                              toolbar: 'undo redo | formatselect | variables | bold italic underline strikethrough | bullist numlist | image | link | searchreplace | code',
                              height: '300px',
                              paste_retain_style_properties: "",
                              paste_preprocess: function(plugin, args) {
                                args.content = args.content.replace(/http\:\/\/www\.arsys/g, 'https://www.arsys');
                                args.content = args.content.replace(/http\:\/\/www\.piensasolutions/g, 'https://www.piensasolutions');
                                args.content = args.content.replace(/ www\.arsys/g, ' https://www.arsys');
                                args.content = args.content.replace(/ www\.piensasolutions/g, ' https://www.piensasolutions');
                                args.content = args.content.replace(/>www\.arsys/g, '>https://www.arsys');
                                args.content = args.content.replace(/>www\.piensasolutions/g, '>https://www.piensasolutions');
                                args.content = args.content.replace(/<div>/g,'<p>');
                                args.content = args.content.replace(/<\/div>/g,'</p>');
                                var urlRegex = /(\b (https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                                args.content = args.content.replace(urlRegex, function(url) {
                                    return ' <a href="' + url.trim() + '">' + url.trim() + '</a>';
                                })
                              },
                              branding: false,
                              branding: false,
                              setup: (editor) => {
                                  editor.addButton('variables', {
                                    type: 'menubutton',
                                    text: 'Variables',
                                    icon: false,
                                    menu: [{
                                      text: '$promocion',
                                      onclick: function() {
                                        editor.insertContent('$promocion');
                                      }
                                    }, {
                                      text: '$nombre_o_empresa',
                                      onclick: function() {
                                        editor.insertContent('$nombre_o_empresa');
                                      }
                                    }, {
                                      text: '$cif_nif',
                                      onclick: function() {
                                        editor.insertContent('$cif_nif');
                                      }
                                    }, {
                                      text: '$nombre_contacto',
                                      onclick: function() {
                                        editor.insertContent('$nombre_contacto');
                                      }
                                    }, {
                                      text: '$url',
                                      onclick: function() {
                                        editor.insertContent('$url');
                                      }
                                    }]
                                  });
                                },
                            }}
                            onChange={this.handleChangeEditorHeaderWith}
                          />
                    ) : (
                    parseInt(this.state.docOutput.unidad) === 3 ? (
                    <Editor apiKey={getEditorTinyApiKey()}
                            key="cabeceravarnicline"
                            initialValue={this.state.docOutput.cabeceraVar}
                            init={{
                              content_style: "html {min-width: 100%;scrollbar-color: #bbb #ddd;scrollbar-width: thin;} " + 
                                                "body {text-align: left;padding: 15px;background: #fff;}" +
                                                "p {font-size: 1.1em;margin: 0 1em 1em 1.8em;}" +
                                                "h1 {color: #af0b12;font-size: 1.2em;background: #f1eee4 ;border-top: 1px solid #bdb8a8;border-bottom: 1px solid #bdb8a8;margin: 1.5em .2em .6em .2em;padding: .2em .2em .2em .4em;}" +
                                                "h2 {color: #000;font-size: 1.2em;font-weight: bold;margin: 1em .2em .6em .2em;}" +
                                                "h3 {color: #af0b12;font-size: 1.2em;font-weight: bold;margin: .4em 0 .1em 1.4em;}" +
                                                "ul {margin: 0 0 .8em 3.2em;font-size: 1.1em;}" +
                                                "li {list-style: none;margin: 0 0 .4em 0;font-size: 12px;}" +
                                                "li:before {content: '\\00bb';margin: 0 1em;color:orange;font-size: 15px;line-height: 12px;font-weight: bold;}" +
                                                "::-webkit-scrollbar {width: 8px;height:8px;}" +
                                                "::-webkit-scrollbar-thumb {background: #bbb;border-radius: 10px;}" +
                                                "::-webkit-scrollbar-track {background: #ddd;}",
                              /*content_css : "http://www.nicline.com/css/styles.css",*/
                              plugins: 'link image code paste lists searchreplace',
                              menu: {},
                              toolbar: 'undo redo | formatselect | bold italic underline strikethrough | bullist numlist | image | link | searchreplace | code',
                              height: '300px',
                              paste_retain_style_properties: "",
                              paste_preprocess: function(plugin, args) {
                                args.content = args.content.replace(/http\:\/\/www\.arsys/g, 'https://www.arsys');
                                args.content = args.content.replace(/http\:\/\/www\.piensasolutions/g, 'https://www.piensasolutions');
                                args.content = args.content.replace(/ www\.arsys/g, ' https://www.arsys');
                                args.content = args.content.replace(/ www\.piensasolutions/g, ' https://www.piensasolutions');
                                args.content = args.content.replace(/>www\.arsys/g, '>https://www.arsys');
                                args.content = args.content.replace(/>www\.piensasolutions/g, '>https://www.piensasolutions');
                                args.content = args.content.replace(/<div>/g,'<p>');
                                args.content = args.content.replace(/<\/div>/g,'</p>');
                                var urlRegex = /(\b (https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                                args.content = args.content.replace(urlRegex, function(url) {
                                    return ' <a href="' + url.trim() + '">' + url.trim() + '</a>';
                                })
                              },
                              branding: false
                            }}
                            onChange={this.handleChangeEditorHeaderWith}
                          />
                    ) : (
                    <Editor apiKey={getEditorTinyApiKey()}
                            key="cabeceravararsys"
                            initialValue={this.state.docOutput.cabeceraVar}
                            init={{
                              content_style: "html {min-width: 100%;scrollbar-color: #bbb #ddd;scrollbar-width: thin;} " + 
                                              "* {font-size: 14px;}" +
                                              "body {text-align: left;padding: 15px;}" +
                                              "p {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;margin-bottom:8px;margin-left:0px;margin-right:30px;margin-top:8px;padding:0;text-align:left;} " + 
                                              "h1 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:32px;font-weight:500;letter-spacing:0.3px;line-height:40px;margin-bottom:24px;margin-left:0px;margin-right:0px;margin-top:48px;padding-bottom:8px;padding:8px 0px;text-align:left;text-transform:uppercase;}" +
                                              "h2 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:24px;font-weight:500;letter-spacing:0.3px;line-height:40px;margin-bottom:0px;margin-left:0px;margin-right:0px;margin-top:0px;padding:0;text-align:left;text-transform:uppercase;} " +
                                              "h3 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:20px;font-weight:500;letter-spacing:-1px;line-height:24px;margin-bottom:0px;margin-left:0px;margin-right:0px;margin-top:16px;padding:0;text-align:left;text-transform:uppercase;}" +
                                              "h4 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:20px;font-weight:500;letter-spacing:-1px;line-height:24px;margin-bottom:16px;margin-left:0px;margin-right:0px;margin-top:16px;padding:0px;text-align:left;text-transform:capitalize;}" +
                                              "h5, h6 {color:rgb(50, 50, 50);display:block;font-family:os, sans-serif;font-size:16px;font-weight:700;line-height:16px;margin:0px;padding:0px;text-align:left;text-transform:none;}" + 
                                              "ul {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-type:circle;margin:16px 24px;padding:0;text-align:left;}" +
                                              "ol {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-type:decimal;margin-bottom:16px;margin-left:24px;margin-right:24px;margin-top:16px;padding:0px;text-align:left;}" +
                                              "li {color:rgb(55, 55, 55);display:list-item;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-image:none;list-style-position:outside;margin-bottom:0px;margin-left:35px;margin-right:0px;margin-top:0px;padding:0;text-align:left;}" +
                                              "br {display: block;}" +
                                              "::-webkit-scrollbar {width: 8px;height:8px;}" +
                                              "::-webkit-scrollbar-thumb {background: #bbb;border-radius: 10px;}" +
                                              "::-webkit-scrollbar-track {background: #ddd;}",
                              content_css : "https://www.arsys.es/resource/style/a.css,https://www.arsys.es/resource/style/legal.css",
                              plugins: 'link image code paste lists searchreplace',
                              menu: {},
                              toolbar: 'undo redo | formatselect | variables | bold italic underline strikethrough | bullist numlist | image | link | searchreplace | code',
                              height: '300px',
                              paste_retain_style_properties: "",
                              paste_preprocess: function(plugin, args) {
                                args.content = args.content.replace(/http\:\/\/www\.arsys/g, 'https://www.arsys');
                                args.content = args.content.replace(/http\:\/\/www\.piensasolutions/g, 'https://www.piensasolutions');
                                args.content = args.content.replace(/ www\.arsys/g, ' https://www.arsys');
                                args.content = args.content.replace(/ www\.piensasolutions/g, ' https://www.piensasolutions');
                                args.content = args.content.replace(/>www\.arsys/g, '>https://www.arsys');
                                args.content = args.content.replace(/>www\.piensasolutions/g, '>https://www.piensasolutions');
                                args.content = args.content.replace(/<div>/g,'<p>');
                                args.content = args.content.replace(/<\/div>/g,'</p>');
                                var urlRegex = /(\b (https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                                args.content = args.content.replace(urlRegex, function(url) {
                                    return ' <a href="' + url.trim() + '">' + url.trim() + '</a>';
                                })
                              },
                              branding: false,
                              setup: (editor) => {
                                  editor.addButton('variables', {
                                    type: 'menubutton',
                                    text: 'Variables',
                                    icon: false,
                                    menu: [{
                                      text: '$dominio',
                                      onclick: function() {
                                        editor.insertContent('$dominio');
                                      }
                                    }, {
                                      text: '$NIF',
                                      onclick: function() {
                                        editor.insertContent('$NIF');
                                      }
                                    }, {
                                      text: '$nombre_empresa_o_persona_fisica',
                                      onclick: function() {
                                        editor.insertContent('$nombre_empresa_o_persona_fisica');
                                      }
                                    }, {
                                      text: '$nombre_representante',
                                      onclick: function() {
                                        editor.insertContent('$nombre_representante');
                                      }
                                    }, {
                                      text: '$nota_promocional',
                                      onclick: function() {
                                        editor.insertContent('$nota_promocional');
                                      }
                                    }, {
                                      text: '$promocion',
                                      onclick: function() {
                                        editor.insertContent('$promocion');
                                      }
                                    }, {
                                      text: '$servicio',
                                      onclick: function() {
                                        editor.insertContent('$servicio');
                                      }
                                    }, {
                                      text: '$texto',
                                      onclick: function() {
                                        editor.insertContent('$texto');
                                      }
                                    }, {
                                      text: '$url',
                                      onclick: function() {
                                        editor.insertContent('$url');
                                      }
                                    }]
                                  });
                                },
                            }}
                            onChange={this.handleChangeEditorHeaderWith}
                          />
                    ))}
                  </div>
                </div>
                <div className="editcontract-content-contract">
                  <div className="editcontract-content-contract-title">
                    Contrato:
                  </div>
                  {parseInt(this.state.docOutput.unidad) === 2 ? (
                  <Editor apiKey={getEditorTinyApiKey()}
                          key="contratpiensa"
                          initialValue={this.state.docOutput.textNotVar}
                          init={{
                            allow_script_urls: true,
                            content_style: "html {min-width: 100%;scrollbar-color: #bbb #ddd;scrollbar-width: thin;} " + 
                                              "body {text-align: left;padding: 15px;background: #fff;min-width: 100%;}" +
                                              "p {color:rgb(79, 79, 79);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;letter-spacing:-0.28px;line-height:20px;margin-bottom:15px;margin-left:0px;margin-right:30px;margin-top:0px;padding:0px;text-align:left;text-decoration-color:rgb(79, 79, 79);text-decoration-line:none;text-decoration-style:solid;text-shadow:rgba(0, 0, 0, 0.3) 0px 0px 1px;}" +
                                              "a {text-decoration: underline;color: #000000;}" +
                                              "h1 {color:rgb(234, 91, 12);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:22px;font-style:normal;font-weight:400;margin:14.74px 0px;padding:0px;text-align:left;}" +
                                              "h2 {color:rgb(234, 91, 12);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:18px;font-style:normal;font-weight:400;margin-bottom:15px;margin-left:0px;margin-right:0px;margin-top:25px;padding:0px;text-align:left;}" +
                                              "h3 {color:rgb(234, 91, 12);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:16px;font-style:normal;font-weight:400;margin:15px 0px;padding:0px;text-align:left;}" +
                                              "h4, h5 {color:rgb(234, 91, 12);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;margin:0px;padding:0px;text-align:left;}" +
                                              "h6 {color:rgb(79, 79, 79);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:16px;font-style:normal;font-weight:400;margin:0px;padding:0px;text-align:left;}" +
                                              "ul {color:rgb(79, 79, 79);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;list-style-image:none;list-style-position:outside;list-style-type:disc;margin-bottom:20px;margin-left:30px;margin-right:0px;margin-top:20px;padding:0px;text-align:left;}" +
                                              "ol {color:rgb(79, 79, 79);display:block;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;list-style-image:none;list-style-position:outside;list-style-type:decimal;margin-bottom:20px;margin-left:30px;margin-right:0px;margin-top:20px;padding:0px;text-align:left;}" +
                                              "li {color:rgb(79, 79, 79);display:list-item;font-family:OSr, Verdana, Arial, sans-serif;font-size:14px;font-style:normal;font-weight:400;margin:0px;padding:0px;text-align:left;}" + 
                                              "table, tr, td {border: 1px solid #000;}" + 
                                              "table tr td {padding: 10px 15px;}" +
                                              "::-webkit-scrollbar {width: 8px;height:8px;}" +
                                              "::-webkit-scrollbar-thumb {background: #bbb;border-radius: 10px;}" +
                                              "::-webkit-scrollbar-track {background: #ddd;}",
                            /*content_css : "https://www.piensasolutions.com/css/piensa.css,https://www.piensasolutions.com/css/ps.legal.css",*/
                            plugins: 'link image code paste lists searchreplace table',
                            menu: {},
                            toolbar: 'undo redo | formatselect | bold italic underline strikethrough | bullist numlist | image | link | table | searchreplace | code',
                            table_default_attributes: {'border': '1'},
                            table_default_styles: {'border-collapse': 'collapse','width': '98%','border':'1px solid #000','border-spacing': '0'},
                            table_appearance_options: false,
                            table_responsive_width: true,
                            height: '500px',
                            paste_retain_style_properties: "",
                            paste_preprocess: function(plugin, args) {
                              args.content = args.content.replace(/http\:\/\/www\.arsys/g, 'https://www.arsys');
                              args.content = args.content.replace(/http\:\/\/www\.piensasolutions/g, 'https://www.piensasolutions');
                              args.content = args.content.replace(/ www\.arsys/g, ' https://www.arsys');
                              args.content = args.content.replace(/ www\.piensasolutions/g, ' https://www.piensasolutions');
                              args.content = args.content.replace(/>www\.arsys/g, '>https://www.arsys');
                              args.content = args.content.replace(/>www\.piensasolutions/g, '>https://www.piensasolutions');
                              args.content = args.content.replace(/<div>/g,'<p>');
                              args.content = args.content.replace(/<\/div>/g,'</p>');
                              var urlRegex = /(\b (https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                              args.content = args.content.replace(urlRegex, function(url) {
                                  return ' <a href="' + url.trim() + '">' + url.trim() + '</a>';
                              })
                            },
                            branding: false
                          }}
                          onChange={this.handleChangeEditorContract}
                        />
                  ) : (
                  parseInt(this.state.docOutput.unidad) === 3 ? (
                  <Editor apiKey={getEditorTinyApiKey()}
                          key="contratnicline"
                          initialValue={this.state.docOutput.textNotVar}
                          init={{
                            allow_script_urls: true,
                            content_style: "html {min-width: 100%;scrollbar-color: #bbb #ddd;scrollbar-width: thin;} " + 
                                              "body {text-align: left;padding: 15px;background: #fff;}" +
                                              "p {font-size: 1.1em;margin: 0 1em 1em 1.8em;}" +
                                              "h1 {color: #af0b12;font-size: 1.2em;background: #f1eee4 ;border-top: 1px solid #bdb8a8;border-bottom: 1px solid #bdb8a8;margin: 1.5em .2em .6em .2em;padding: .2em .2em .2em .4em;}" +
                                              "h2 {color: #000;font-size: 1.2em;font-weight: bold;margin: 1em .2em .6em .2em;}" +
                                              "h3 {color: #af0b12;font-size: 1.2em;font-weight: bold;margin: .4em 0 .1em 1.4em;}" +
                                              "ul {margin: 0 0 .8em 3.2em;font-size: 1.1em;}" +
                                              "li {list-style: none;margin: 0 0 .4em 0;font-size: 12px;}" +
                                              "li:before {content: '\\00bb';margin: 0 1em;color:orange;font-size: 15px;line-height: 12px;font-weight: bold;}" +
                                              "::-webkit-scrollbar {width: 8px;height:8px;}" +
                                              "::-webkit-scrollbar-thumb {background: #bbb;border-radius: 10px;}" +
                                              "::-webkit-scrollbar-track {background: #ddd;}",
                            /*content_css : "http://www.nicline.com/css/styles.css",*/
                            plugins: 'link image code paste lists searchreplace',
                            menu: {},
                            toolbar: 'undo redo | formatselect | bold italic underline strikethrough | bullist numlist | image | link | searchreplace | code',
                            height: '500px',
                            paste_retain_style_properties: "",
                            paste_preprocess: function(plugin, args) {
                              args.content = args.content.replace(/http\:\/\/www\.arsys/g, 'https://www.arsys');
                              args.content = args.content.replace(/http\:\/\/www\.piensasolutions/g, 'https://www.piensasolutions');
                              args.content = args.content.replace(/ www\.arsys/g, ' https://www.arsys');
                              args.content = args.content.replace(/ www\.piensasolutions/g, ' https://www.piensasolutions');
                              args.content = args.content.replace(/>www\.arsys/g, '>https://www.arsys');
                              args.content = args.content.replace(/>www\.piensasolutions/g, '>https://www.piensasolutions');
                              args.content = args.content.replace(/<div>/g,'<p>');
                              args.content = args.content.replace(/<\/div>/g,'</p>');
                              var urlRegex = /(\b (https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                              args.content = args.content.replace(urlRegex, function(url) {
                                  return ' <a href="' + url.trim() + '">' + url.trim() + '</a>';
                              })
                            },
                            branding: false
                          }}
                          onChange={this.handleChangeEditorContract}
                        />
                  ) : (
                  <Editor apiKey={getEditorTinyApiKey()}
                          key="contratarsys"
                          initialValue={this.state.docOutput.textNotVar}
                          init={{
                            allow_script_urls: true,
                            content_style: "html {min-width: 100%;scrollbar-color: #bbb #ddd;scrollbar-width: thin;} " + 
                                              "body {text-align: left;padding: 15px;}" +
                                              "* {font-size: 14px;}" +
                                              "p {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;margin-bottom:8px;margin-left:0px;margin-right:30px;margin-top:8px;padding:0;text-align:left;} " + 
                                              "h1 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:32px;font-weight:500;letter-spacing:0.3px;line-height:40px;margin-bottom:24px;margin-left:0px;margin-right:0px;margin-top:48px;padding-bottom:8px;padding:8px 0px;text-align:left;text-transform:uppercase;}" +
                                              "h2 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:24px;font-weight:500;letter-spacing:0.3px;line-height:40px;margin-bottom:0px;margin-left:0px;margin-right:0px;margin-top:0px;padding:0;text-align:left;text-transform:uppercase;} " +
                                              "h3 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:20px;font-weight:500;letter-spacing:-1px;line-height:24px;margin-bottom:0px;margin-left:0px;margin-right:0px;margin-top:16px;padding:0;text-align:left;text-transform:uppercase;}" +
                                              "h4 {color:rgb(0, 0, 0);display:block;font-family:fs;font-size:20px;font-weight:500;letter-spacing:-1px;line-height:24px;margin-bottom:16px;margin-left:0px;margin-right:0px;margin-top:16px;padding:0px;text-align:left;text-transform:capitalize;}" +
                                              "h5, h6 {color:rgb(50, 50, 50);display:block;font-family:os, sans-serif;font-size:16px;font-weight:700;line-height:16px;margin:0px;padding:0px;text-align:left;text-transform:none;}" + 
                                              "ul {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-type:circle;margin:16px 24px;padding:0;text-align:left;}" +
                                              "ol {color:rgb(55, 55, 55);display:block;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-type:decimal;margin-bottom:16px;margin-left:24px;margin-right:24px;margin-top:16px;padding:0px;text-align:left;}" +
                                              "li {color:rgb(55, 55, 55);display:list-item;font-family:os;font-size:16px;font-weight:500;line-height:24px;list-style-image:none;list-style-position:outside;margin-bottom:0px;margin-left:35px;margin-right:0px;margin-top:0px;padding:0;text-align:left;}" + 
                                              "table, tr, td {border: 1px solid #000;}" + 
                                              "table tr td {padding: 10px 15px;}" +
                                              "br {display: block;}" +
                                              "::-webkit-scrollbar {width: 8px;height:8px;}" +
                                              "::-webkit-scrollbar-thumb {background: #bbb;border-radius: 10px;}" +
                                              "::-webkit-scrollbar-track {background: #ddd;}",
                            content_css : "https://www.arsys.es/resource/style/a.css,https://www.arsys.es/resource/style/legal.css",
                            plugins: 'link image code paste lists searchreplace table',
                            menu: {},
                            toolbar: 'undo redo | formatselect | bold italic underline strikethrough | bullist numlist | image | link | table | searchreplace | code',
                            table_default_attributes: {'border': '1'},
                            table_default_styles: {'border-collapse': 'collapse','width': '98%','border':'1px solid #000','border-spacing': '0'},
                            table_appearance_options: false,
                            table_responsive_width: true,
                            height: '500px',
                            paste_retain_style_properties: "",
                            paste_preprocess: function(plugin, args) {
                              args.content = args.content.replace(/http\:\/\/www\.arsys/g, 'https://www.arsys');
                              args.content = args.content.replace(/http\:\/\/www\.piensasolutions/g, 'https://www.piensasolutions');
                              args.content = args.content.replace(/ www\.arsys/g, ' https://www.arsys');
                              args.content = args.content.replace(/ www\.piensasolutions/g, ' https://www.piensasolutions');
                              args.content = args.content.replace(/>www\.arsys/g, '>https://www.arsys');
                              args.content = args.content.replace(/>www\.piensasolutions/g, '>https://www.piensasolutions');
                              args.content = args.content.replace(/<div>/g,'<p>');
                              args.content = args.content.replace(/<\/div>/g,'</p>');
                              var urlRegex = /(\b (https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
                              args.content = args.content.replace(urlRegex, function(url) {
                                  return ' <a href="' + url.trim() + '">' + url.trim() + '</a>';
                              })
                            },
                            branding: false
                          }}
                          onChange={this.handleChangeEditorContract}
                        />
                  ))}
                </div>
              </div>
          </div>
          {this.state.showLoading ? <Loading /> : null}
        </div>
      </div>
        );
    }
}

export default CloneContract;