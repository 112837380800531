import React, { Component } from 'react';
import './styles.css';
import {authParams} from '../Lib/functions';

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username:'',
      password:'',
      loginError:'',
    }
  }

  handleChangeUsername = (event) => {
    this.setState({username:event.target.value});
  }

  handleChangePassword = (event) => {
    this.setState({password:event.target.value});
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.checkEmail()
    }
  }

  checkEmail = () => {
    var md5 = require('md5');
    this.getLogin({username:this.state.username,password:md5(this.state.password)})
      .then(res => {
        //console.log(res.data[0]);
        this.props.correctUser(res.data[0]);
      })
      .catch(error => {
        //alert(error);
        this.setState({loginError: error});
      });
  }

  getLogin = (obj) => {
    return new Promise((resolve, reject) => {
      
      var timestamp = new Date().getTime();
      let authParam = authParams(timestamp);
      let urlServer = this.props.serverRoute + '/login?t='+ timestamp + '&k=' + authParam;
      //let urlServer = this.props.serverRoute + '/login';
      fetch(urlServer, {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(res => res.json())
        .then(json => {
          if (json.Response === 'True') {
            if (json.data.length === 0) {
              reject('Incorrect login. Try again.');
            } else {
              resolve(json);
            }
          } else {
            reject(json.Error);
          }
        })
        .catch(err => {
          console.log(err);
          reject('api error');
        });
        
        //resolve(customData);
    });
  }

  render() {
    return (
      <div className="onboarding-bg">
        <div className="onboarding-bg-in">
          <div className="onboarding-header">
            <span>Backend :: Login</span>
          </div>
          <div className="onboarding-row">  
    				<div className="onboarding-title">Username</div>
    				<input value={this.state.username} type="text" onChange={this.handleChangeUsername} onKeyPress={this.handleKeyPress} placeholder="Your username" />
          </div>
				  <div className="onboarding-row">
            <div className="onboarding-title">Password</div>
		    		<input value={this.state.password} type="password" onChange={this.handleChangePassword} onKeyPress={this.handleKeyPress} placeholder="Your password" />
          </div>
				  <div className="onboarding-button" onClick={() => this.checkEmail()}>LOGIN</div>
			  </div>
        {this.state.loginError !== '' ? (
          <div className="loginerror-bg">
            <div className="loginerror-bg-in">
              <div className="loginerror-bg-text">{this.state.loginError}</div>
              <div className="loginerror-bg-button" onClick={() => this.setState({loginError:''})}>OK</div>
            </div>
          </div>
        ): null}
      </div>
    );
  }
}

export default Loading;
