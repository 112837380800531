import React from 'react';
import PreviewContratArsys from './PreviewContratArsys';
import PreviewContratPiensa from './PreviewContratPiensa';
import PreviewContratNicline from './PreviewContratNicline';
import './styles.css';

const PreviewContrat = ({ content, closePreviewContract }) => {
    return (
        <div className="preview-contrat">
        <div className="preview-contrat-in">
          <div className="preview-contrat-close" onClick={() => closePreviewContract()}></div>
          <div className="preview-contrat-content">
          {parseInt(content.unidad) === 2 ?
          	<PreviewContratPiensa content={content} />
          : parseInt(content.unidad) === 3 ?
          	<PreviewContratNicline content={content} />
          : <PreviewContratArsys content={content} />
          }
          </div>
		</div>
        </div>
    );

}

export default PreviewContrat;